import OutcomeReview from "../../../../../types/Panelist/OutcomeReview";
import { QubedOutcome } from "../../../../../types/Panelist/QubedOutcome";
import { USER_ROLES, USER_SINGLE_ROLES, PERMISSIONS } from "../../../../../types/User/UserRoles";
import useAuthorization from "../../../../Auth/useAuthorization";
import QualityCheckList from "./Components/QualityCheckList";
import ReviewModal from "../Components/ReviewModal";


type QualityCheckProps = {
    qubedOutcome: QubedOutcome | undefined;
    duplicatePanelists: Array<string>;
    panelistId: string;
    reviews: Array<OutcomeReview>;
    hasDuplications: boolean;
}
const QualityCheckContainer = ({ ...qualityCheckProps }: QualityCheckProps) => {
    const { isAuthorized } = useAuthorization([USER_ROLES.QUALITY_MANAGEMENT, ...Object.values(USER_SINGLE_ROLES)], [PERMISSIONS.EXECUTE_BASED_ON_CHECKS, PERMISSIONS.EXECUTE_ALL, PERMISSIONS.MANAGE_REVIEWS]);
    const { qubedOutcome } = qualityCheckProps;

    return (
        <>
            {!qubedOutcome?.quality_checks?.length ? <></> :
            <QualityCheckList displayAllChecks={isAuthorized} {...qualityCheckProps} />
            }
            <ReviewModal { ...qualityCheckProps }/>
        </>
    );


}

export default QualityCheckContainer;
