import ReactECharts from 'echarts-for-react'
import QubedEventVolumeTimeseriesDataPoint from '../../../../types/timeseries/QubedEventVolumeTimeseriesDataPoint';

const DailyPanelistFlagVolumeChart: React.FC<{
  dailyPanelistFlagVolumeState: QubedEventVolumeTimeseriesDataPoint[] }> = ({ dailyPanelistFlagVolumeState }) => {
  const chartData = dailyPanelistFlagVolumeState.map(({outcomeCount}) => (outcomeCount));
  const chartLabels = dailyPanelistFlagVolumeState.map(({_id}) => (new Date(_id.date).getDate() + '/' + (new Date(_id.date).getMonth() + 1 )));

  const options = {
    grid: { top: 50, right: 8, bottom: 24, left: 45 },
    xAxis: {
      type: 'category',
      data: chartLabels,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: chartData,
        type: 'line',
        smooth: false,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
    title: {
      text: 'Qubed Events Generated per Day'
    }
  };

  return (
    <section className='section'>
      <ReactECharts
        option={options}
        theme={'vintage'}/>
    </section>
  )
}

export default DailyPanelistFlagVolumeChart;
