import { useFetch } from "../../../hooks/useFetch";
import Panelist from "../../../types/Panelist/PanelistEntry";
import Review from "../../../types/Review";


export const useProcessReviewRequest = () => {
  const { callProtectedEndpoint } = useFetch();
  const processReviewRequest = async (reviewRequestPatch: Review, panel: string, reviewRequestId: string): Promise<Panelist> => {
    const patchRequestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(reviewRequestPatch),
    };

    return await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${panel}/review_requests/process/${reviewRequestId}`, patchRequestOptions);
  }
  
  return { processReviewRequest };
};
