import ReactJson from '@microlink/react-json-view';

const QualityCheckItem: React.FC<{
  name: string;
  reason: string | JSX.Element[];
}> = ({ name, reason }) => {
  let parsedReason: object | null = null;
  // TODO:need to change this method
  const isValidJSON = (jsonString : string)=> {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }
  if (typeof reason === 'string' && isValidJSON(reason)) {
      parsedReason = JSON.parse(reason);
  }

  return (
    <tr>
      <th>{name}</th>
      <td>{parsedReason ? (<ReactJson src={{...parsedReason}} theme="rjv-default"  displayObjectSize={false} enableClipboard={false}/>) : (reason)}</td>
    </tr>
  );
};
export default QualityCheckItem;
