const ErrorMessage: React.FC<{
  setErrorMessageActive: React.Dispatch<React.SetStateAction<boolean>>,
  header: string,
  message: string }> = ({ setErrorMessageActive, header, message }) => {
  return (
    <article className="message is-small is-danger">
      <div className="message-header">
        <p>{header}</p>
        <button onClick={ () => {setErrorMessageActive(false)}} className="delete is-small" aria-label="delete"></button>
      </div>
      <div className="message-body">
        <p>{message}</p>
      </div>
    </article>
  )
}

export default ErrorMessage;
