import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import { QualityCheck, QubedOutcome } from "../../../../../../types/Panelist/QubedOutcome";
import QualityCheckItem from "./QualityCheckItem";


const QualityCheckList: React.FC<{
  qubedOutcome: QubedOutcome | undefined;
  duplicatePanelists: Array<string>;
  hasDuplications: boolean;
  displayAllChecks: boolean;
}> = ({ qubedOutcome, duplicatePanelists, hasDuplications, displayAllChecks }) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const navigateToPanelistProfile = (id: string, e: any) => {
    e.preventDefault();
     const pathParts = pathname.split('/');
     pathParts[pathParts.length - 1] = id;
     const newPath = pathParts.join('/');
    navigate(newPath);
  };
  const setupPanelistLinks = (panelistIds: string[]) => {
    return panelistIds.map((id: string, index) =>
    (<>
      {index > 0 && ' , '}
      <Link key={index} underline="hover" onClick={(e) => navigateToPanelistProfile(id, e)}>
        {id}
      </Link>
    </>)
    );
  };

  const normalizeString = (name: string): string => {
    return name.replace(/_/g, " ");
  };

  return (
    <>
      {hasDuplications ? (
        <nav className="panel is-dark">
          <p className="panel-heading text-centered">Quality Checks</p>
          <div className="panel-block">
            <table className="table is-bordered is-hoverable is-striped is-fullwidth">
              <tbody>
                {qubedOutcome?.quality_checks
                  ?.filter((item: QualityCheck) => item.outcome !== "good")
                  .map((qualityCheckItem: QualityCheck, index: number) => {
                    if (qualityCheckItem.name === "quality_check_panel_user_duplication") {
                      return (
                        <QualityCheckItem
                          key={index}
                          name={normalizeString(qualityCheckItem.name)}
                          reason={setupPanelistLinks(duplicatePanelists)}
                        />
                      );
                    }
                    if (displayAllChecks) {
                      return (
                        <QualityCheckItem
                          key={index}
                          name={normalizeString(qualityCheckItem.name)}
                          reason={qualityCheckItem.reason}
                        />
                      );
                    }
                    return <tr key={index}></tr>;
                  })}
              </tbody>
            </table>
          </div>
        </nav>
      ) : (
        <></>
      )}
    </>
  );
};

export default QualityCheckList;
