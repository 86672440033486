import React from 'react'
import ReactECharts from 'echarts-for-react'
import EventVolumeTimeseriesDataPoint from '../../../../types/timeseries/EventVolumeTimeseriesDataPoint';

const HourlyEventVolumeChart: React.FC<{
  hourlyEventVolumeState: EventVolumeTimeseriesDataPoint[] }> = ({ hourlyEventVolumeState }) => {
  const chartData = hourlyEventVolumeState.map(({eventCount}) => (eventCount));
  const chartLabels = hourlyEventVolumeState.map(({_id}) => (new Date(_id.date).getHours() + ':00'))

  const options = {
    grid: { top: 50, right: 8, bottom: 24, left: 80 },
    xAxis: {
      type: 'category',
      data: chartLabels,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: chartData,
        type: 'bar',
        smooth: false,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
    title: {
      text: 'Total Number of Events per Hour'
    }
  };

  return (
    <section className='section'>
      <ReactECharts
      option={options}
      theme={'vintage'}/>
    </section>
  )
}

export default HourlyEventVolumeChart;
