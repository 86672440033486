import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';

const ProtectedRoute = ({ component, ...args }: any) => {
  if (process.env.NODE_ENV === 'production') {
    const Component = withAuthenticationRequired(component, { ...args,
      onRedirecting: () => <LinearProgress />,
    });
    return <Component />;
  }
  return React.createElement(component);
};

export default ProtectedRoute;
