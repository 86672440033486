import { useState } from "react";
import OutcomeReview from "../../../../../types/Panelist/OutcomeReview";
import Pagination from "../../../../Pagination/Pagination";
import { ManualReviewItem } from "./ManualReviewItem";

type ManualReviewProps = {
  outcomeReviews?: Array<OutcomeReview>
}

const ManualReviewList: React.FC<ManualReviewProps> = ({ outcomeReviews }) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);

  const chunkSize =  5;
  const activityChunk = outcomeReviews?.slice(chunkSize * selectedPageIndex, chunkSize * selectedPageIndex + chunkSize);
  const activityChunkStartIndex = chunkSize * selectedPageIndex;

  const getReviews = (pageStartIndex: number, pageEntries?: Array<OutcomeReview>) => {
    return (
      <>
        {pageEntries?.map((reviewItem: OutcomeReview, index: number) => (
          <ManualReviewItem key={index + pageStartIndex} reviews={reviewItem} />
        ))}
      </>
    );
  };

  return (
    <>
      {getReviews(activityChunkStartIndex, activityChunk)}
      <div className='panel-block'>
        <Pagination pagesAmount={Math.ceil((outcomeReviews?.length || 0) / chunkSize)} selectedPageIndex={selectedPageIndex} setSelectedPageIndex={setSelectedPageIndex} />
      </div>
    </>
  );
}

export default ManualReviewList;
