import DailyEventVolumeChart from '../../Charts/EventMetrics/DailyEventVolume/DailyEventVolumeChart';
import HourlyEventVolumeChart from '../../Charts/EventMetrics/HourlyEventVolume/HourlyEventVolumeChart';
import { EventDistributionByNameChart24h, EventDistributionByNameChart7Days} from '../../Charts/EventMetrics/DailyEventVolumeByName/DailyEventVolumeByNameChart';
import EventVolumeTimeseriesDataPoint from '../../../types/timeseries/EventVolumeTimeseriesDataPoint';
import EventDistributionByNameTimeseriesDataPoint from '../../../types/timeseries/EventDistributionByNameTimeseriesDataPoint';
import Loading from '../../Helpers/loading';

const EventMetricsContainer: React.FC<{
  dailyEventVolumeState: EventVolumeTimeseriesDataPoint[],
  hourlyEventVolumeState: EventVolumeTimeseriesDataPoint[],
  eventDistributionByNameLast7DaysState: EventDistributionByNameTimeseriesDataPoint[],
  eventDistributionByNameLast24HoursState: EventDistributionByNameTimeseriesDataPoint[] }> = ({ dailyEventVolumeState, hourlyEventVolumeState, eventDistributionByNameLast7DaysState, eventDistributionByNameLast24HoursState }) => {
  return (
    <div className='container'>
      <nav className="panel is-dark container is-max-desktop">
        <p className="panel-heading text-centered">
          Event Metrics
        </p>
        <a className="panel-block">
          { dailyEventVolumeState.length === 0 ? <Loading/> : <DailyEventVolumeChart  dailyEventVolumeState={dailyEventVolumeState}/> }
        </a>
        <a className="panel-block">
          { hourlyEventVolumeState.length === 0 ? <Loading/> : <HourlyEventVolumeChart hourlyEventVolumeState={hourlyEventVolumeState}/> }
        </a>
        <a className="panel-block">
          { eventDistributionByNameLast7DaysState.length === 0 ? <Loading/> : <EventDistributionByNameChart7Days eventDistributionByNameLast7DaysState={eventDistributionByNameLast7DaysState}/> }
        </a>
        <a className="panel-block">
          { eventDistributionByNameLast24HoursState.length === 0 ? <Loading/> : <EventDistributionByNameChart24h eventDistributionByNameLast24HoursState={eventDistributionByNameLast24HoursState}/> }
        </a>
      </nav>
    </div>
  )
}

export default EventMetricsContainer;
