import { TableRow, TableCell, Table, Typography } from "@mui/material";
import Demographics from "../../types/Panelist/Demographics";
import { StyledTableCell, basicInformationRowsData, employmentInfoRowsData } from "../PanelistProfile/Tabs/DemographicsTab/DemographicsTable";
import { AccessAlarm } from "@mui/icons-material";

const DemographicsComparison: React.FC<{
  currentDemographics: Demographics | undefined,
  currentDemographicsTimestamp: string | undefined,
  previousDemographics: Demographics | undefined,
  previousDemographicsTimestamp: string | undefined,
}> = ({ currentDemographics, previousDemographics, currentDemographicsTimestamp, previousDemographicsTimestamp }) => {
  const changeHighlightColor: string = '#FFFF99';

  const demographicsRows = [ ...basicInformationRowsData, ...employmentInfoRowsData];

  const ChangeHighlightRow = ({ icon, label, currentValue, previousValue }: any) => (
    <TableRow style={!!previousValue && currentValue !== previousValue ? { background: changeHighlightColor } : {}}>
      <StyledTableCell component="th" scope="row">
        {icon} {label}
      </StyledTableCell>
      <TableCell>{currentValue?.toString() || '-'}</TableCell>
      <TableCell>{previousValue?.toString() || '-'}</TableCell>
    </TableRow>
  );

  const getDemographicValue = (demographics?: Demographics, searchKey?:string)=>{
    if(demographics){
      const result = Object.entries(demographics).find(([key])=> key === searchKey);
      return result ? result[1] : '';
    }
    return undefined;
  }
  return (
    <Table className='table is-hoverable is-fullwidth'>
      <tbody>
        <TableRow>
          <TableCell style={{ border: 'none' }} colSpan={0}></TableCell>
          <TableCell>
            <Typography variant="subtitle1">Latest Demographics</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="subtitle1">Previous Demographics</Typography>
          </TableCell>
        </TableRow>
        <TableRow key={'time_stamp'}>
            <StyledTableCell component="th" scope="row">
              <AccessAlarm /> {'Timestamp'}
            </StyledTableCell>
            <TableCell>{currentDemographicsTimestamp}</TableCell>
            <TableCell>{previousDemographicsTimestamp}</TableCell>
          </TableRow>
        {demographicsRows.map((row) => (
          <ChangeHighlightRow
            key={row.key}
            label={row.label}
            icon={row.icon}
            currentValue={getDemographicValue(currentDemographics, row.key)}
            previousValue={getDemographicValue(previousDemographics, row.key)}
          />
        ))}
      </tbody>
    </Table>
  )
}

export { DemographicsComparison };
