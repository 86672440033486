import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { MouseEventHandler } from 'react';

type NavbarItemProps = {
  idx: number;
  onClickHandler?: MouseEventHandler<HTMLAnchorElement>;
  path: string;
  icon: JSX.Element;
  title: string;
};

const NavbarItem: React.FC<NavbarItemProps> = (item) => {
  return (
    <Link
      key={item.idx}
      className="navbar-item nav-text"
      to={item.path}
      onClick={item.onClickHandler}
    >
      <Typography>
        <IconButton color="inherit" size="large" style={{paddingBottom: '1rem'}}>
          {item.icon}
        </IconButton>
        <span style={{ whiteSpace: 'nowrap' }}>{item.title}</span>
      </Typography>
    </Link>
  );
};

export default NavbarItem;
