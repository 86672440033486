import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Overview from './pages/Overview';
import PanelistProfileContainer from './pages/PanelistProfileContainer';
import './App.scss'
import { registerThemes } from './chart_themes/echartThemes';
import ProtectedRoute from './auth/protected-route';
import Landing from './pages/Landing';
import ReviewRequests from './pages/ReviewRequests';
import { useAuth0 } from '@auth0/auth0-react';
import { allowedPanels, AUTH0_AUDIENCE_ROLES } from './auth/account-info';
import Home from './pages/Home';
import ErrorPage from './pages/ErrorPage';
import { USER_ROLES, USER_SINGLE_ROLES } from './types/User/UserRoles';
import Audits from './pages/AuditsPage/Audits';

registerThemes();

const App: React.FC<{}> = () => {
  const { user } = useAuth0();
  const [selectedPanel, setSelectedPanel] = useState<string>("");
  const [permittedPanels, setPermittedPanels] = useState<string[]>([]);
  const [userRoles, setUserRoles] = useState<string[]>([]); 
  
  const getUserRoles = () =>{
    if (user) {
     setUserRoles(user[AUTH0_AUDIENCE_ROLES]);
    }
  }
  
  const getPanels = () => {
    if(!!userRoles){
      const panels = allowedPanels(userRoles);
      setPermittedPanels(panels);
      setSelectedPanel(panels[0]);
    }  
  }
  useEffect(() => {
    getUserRoles();
    getPanels();
    // eslint-disable-next-line
  }, [user, userRoles]);

  const isAuthorized = userRoles.some((role) => [USER_SINGLE_ROLES.ADMIN, USER_ROLES.QUALITY_MANAGEMENT].includes(role as USER_SINGLE_ROLES | USER_ROLES));
  const props = { panels : permittedPanels, selectedPanel, setSelectedPanel}
  return (
    <Routes>
      <Route path='/' element={<Landing />} />
      <Route path='/overview' element={<ProtectedRoute component={() => <Overview {...props} />} />} />
      <Route path='/panelists' element={<ProtectedRoute component={() => <Home {...props} />} />} />
      <Route path='/panelists/:panel/:id' element={<ProtectedRoute component={() => <PanelistProfileContainer {...props} />} />} />
      <Route path='/review_requests' element={<ProtectedRoute component={() => isAuthorized ? <ReviewRequests {...props} /> : <ErrorPage errorCode={403} />} />} />
      <Route path='/reviews' element={<ProtectedRoute component={() => isAuthorized ? <Audits {...props}/> : <ErrorPage errorCode={403} />} />} />
    </Routes>
  );
}

export default App;
