import React from 'react'

const Landing:React.FC<{}> = () => {
  return (
    <div>
      <div className="hero is-fullheight">
        <div className="hero-body is-justify-content-center is-align-items-center">
          <div className="column is-3 text-centered">
            <div className="card">
              <div className='card-content'>
                <div className="columns is-flex is-flex-direction-column">
                  <div className="column">
                    <img src={window.location.origin + '/img/qubed_logo.png'} alt="qubed logo"/>
                  </div>
                  <div className="column">
                      <h1 className='title is-3'> Qubed Admin Portal </h1>
                  </div>
                  <div className="column">
                    <form action="/panelists">
                      <button className="button is-link is-outlined is-fullwidth" type="submit">Login</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing;
