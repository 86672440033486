import { useFetch } from "../../../../../hooks/useFetch";
import OutcomeReview from "../../../../../types/Panelist/OutcomeReview";
import Panelist from "../../../../../types/Panelist/PanelistEntry";

export const usePostReview = () => {
  const { callProtectedEndpoint } = useFetch();
  const postManualReview = async (review: OutcomeReview, panelistId: string): Promise<Panelist> => {
    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(review),
    };

    let [ panel, id ] = panelistId.split(':');

    const endpointResponse = await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${panel}/panelists/${id}/outcome_review`, postRequestOptions);
  
    return endpointResponse;
  }
  
  return { postManualReview };
};
