import { Link } from 'react-router-dom';
import { Card, CardMedia, CardContent, Typography, Button, styled } from '@mui/material';

const StyledCard = styled(Card)`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const StyledLogo = styled(CardMedia)`
  display: inline !important;
  width: 21.875rem; /* 350px / 16px */
  height: 18.75rem; /* 300px / 16px */
  margin-bottom:25px;
`;

const StyledContent = styled(CardContent)`
  text-align: center;
`;
const ErrorPage = ({ errorCode }:{errorCode:Number}) => {
  let errorMessage, errorDescription;

  switch (errorCode) {
    case 403:
      errorMessage = 'Access Forbidden';
      errorDescription = 'You do not have permission to access this resource.';
      break;
    case 404:
      errorMessage = 'Panelist Not Found';
      errorDescription = 'The panelist you are looking for may not be in the Qubed system yet or might not exist.';
      break;
    default:
      errorMessage = 'Error';
      errorDescription = 'An unexpected error occurred.';
  }

  return (
    <StyledCard>
      <StyledContent>
      <StyledLogo
        component="img"
        alt="Company Logo"
        image={window.location.origin + '/img/qubed_logo.png'}
      />
        <Typography variant="h5" component="div" gutterBottom>
          {errorMessage}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {errorDescription}
        </Typography>
        <Button component={Link} to="/panelists" variant="contained" color="primary" style={{ marginTop: '16px' }}>
          Search for Another panelist
        </Button>
      </StyledContent>
    </StyledCard>
  );
};

export default ErrorPage;
