import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const formatDateTimestamp = (date: string | null | undefined): string | null => {
  if (!date) return null;
  const formattedDate = dayjs(date).format('D MMM YYYY HH:mm:ss');
  const relativeTimeStr = dayjs(date).fromNow();
  return `${formattedDate} (${relativeTimeStr})`;
};

const timePassedfromDate = (date: string | null | undefined, comparisonDate: string | null | undefined,): string | null => {
  if (!date || !comparisonDate) return null;
  return dayjs(comparisonDate).from(date);
};

export { formatDateTimestamp, timePassedfromDate };
