enum PERMISSIONS {
    VIEW_BASICS = "read:panelist:outcomes",
    READ_NOTES_REQUESTS = "read:panelist:notes",
    WRITE_NOTES_REQUESTS = "write:panelist:notes",
    VIEW_ADVANCE_INFO = "read:panelist:all",
    VIEW_ALL = "read:all",
    EXECUTE_ALL = "write:reviews:all",
    MANAGE_REVIEWS = "manage:reviews:all",
    EXECUTE_BASED_ON_CHECKS = "write:reviews:basic_checks"
}

enum USER_ROLES {
    STANDARD_ACCESS = "Qubed.Portal.StandardAccess",
    ADVANCED_ACCESS = "Qubed.Portal.AdvancedAccess",
    QUALITY_MANAGEMENT = "Qubed.Portal.QualityManagement"
}

enum USER_DATA_ROLES {
    QMEE_PANEL = "Qubed.Portal.QmeePanel",
    LIFEPOINTS_PANEL = "Qubed.Portal.LifepointsPanel",
    MOBIWORKX_PANEL = "Qubed.Portal.MobiworkxPanel"
}

enum USER_SINGLE_ROLES {
    ADMIN = "Qubed.Portal.AdminAccess"
}
export type USER_ACCESS_CONTROLS = USER_ROLES | USER_SINGLE_ROLES | USER_DATA_ROLES;

export { PERMISSIONS, USER_ROLES, USER_SINGLE_ROLES, USER_DATA_ROLES };