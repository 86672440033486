import OutcomeReview from "../../../../../types/Panelist/OutcomeReview";
import { formatDateTimestamp } from "../../../../../utils/FormatDateTimestamp";


type ManualReviewItemProps = {
  reviews: OutcomeReview
}

export const ManualReviewItem: React.FC<ManualReviewItemProps> = ({ reviews }) => {
  const { timestamp, author, outcome, reason } = reviews;
  return (
    <div className='panel-block'>
      <div className='card'>
        <header className='card-header'>
          <p className='card-header-title'>
            {formatDateTimestamp(timestamp as string) || '-'}
          </p>
        </header>
        <div className='card-content'>
          <div className='content is-size-7 overflow-scroll'>
            <table className='table is-bordered is-hoverable is-striped is-fullwidth'>
              <tbody>
                <tr>
                  <th>author:</th>
                  <td>{author || '-'}</td>
                </tr>
                <tr>
                  <th>{outcome === 'auto' ? 'comments or remarks:' : 'reason:'} </th>
                  <td>{reason || '-'}</td>
                </tr>
                <tr>
                  <th>final outcome:</th>
                  <td>{outcome || '-'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}