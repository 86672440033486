const SuccessMessage: React.FC<{
  setSuccessMessageActive: React.Dispatch<React.SetStateAction<boolean>>,
  header: string,
  message: string }> = ({ setSuccessMessageActive, header, message }) => {
  return (
    <article className="message is-small is-success">
      <div className="message-header">
        <p>{header}</p>
        <button onClick={ () => {setSuccessMessageActive(false)}} className="delete is-small" aria-label="delete"></button>
      </div>
      <div className="message-body">
        <p>{message}</p>
      </div>
    </article>
  )
}

export default SuccessMessage;
