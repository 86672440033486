import { useAuth0 } from '@auth0/auth0-react';

type fetchApiOptions = {
  headers?: object
}

export const useFetch = () => {
  const { getAccessTokenSilently } = useAuth0();

  const callProtectedEndpoint = async (url: string, options: fetchApiOptions = {}) => {
    const accessToken = await getAccessTokenSilently();
    return await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => response.json())
      .catch ((error) => console.error(error));
  }

  return { callProtectedEndpoint };
};
