import React from 'react'
import ReactECharts from 'echarts-for-react'
import EventDistributionByNameTimeseriesDataPoint from '../../../../types/timeseries/EventDistributionByNameTimeseriesDataPoint';

const convertNumberToPercentageArray = ( (numberArray: number[]) => {
  const sum = numberArray.reduce((a, b) => a + b, 0);
  return numberArray.map(number => ((number / sum) * 100).toFixed(2));
});

const EventDistributionByNameChart24h: React.FC<{
  eventDistributionByNameLast24HoursState: EventDistributionByNameTimeseriesDataPoint[] }> = ({ eventDistributionByNameLast24HoursState }) => {
  const chartData = convertNumberToPercentageArray(eventDistributionByNameLast24HoursState.map(({count}) => (count)));
  const chartLabels = eventDistributionByNameLast24HoursState.map(({_id}) => (_id));

  const options = {
    title: {
      text: 'Event Distribution by Name - Last 24 Hours'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} %'
      },
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: chartLabels
    },
    series: [
      {
        name: '%',
        type: 'bar',
        data: chartData
      }
    ]
  };

  return (
    <section className='section'>
      <ReactECharts 
        option={options}
        theme={'vintage'}/>
    </section>
  )
}

const EventDistributionByNameChart7Days: React.FC<{
  eventDistributionByNameLast7DaysState: EventDistributionByNameTimeseriesDataPoint[] }> = ({ eventDistributionByNameLast7DaysState }) => {
  const chartData = convertNumberToPercentageArray(eventDistributionByNameLast7DaysState.map(({count}) => (count)));
  const chartLabels = eventDistributionByNameLast7DaysState.map(({_id}) => (_id));

  const options = {
    title: {
      text: 'Event Distribution by Name - Last 7 Days'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} %'
      },
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: chartLabels
    },
    series: [
      {
        name: '%',
        type: 'bar',
        data: chartData
      }
    ]
  };

  return (
    <section className='section'>
      <ReactECharts
        option={options}
        theme={'vintage'}/>
    </section>
  )
}

export { EventDistributionByNameChart24h, EventDistributionByNameChart7Days };
