const OverviewStatsLevel: React.FC<{
  eventVolumeLast7days: number,
  qubedVolumeLast7days: number,
  completionRate: number }> = ({ eventVolumeLast7days, qubedVolumeLast7days, completionRate }) => {
  return (
    <nav className='level'>
      <div className='level-item has-text-centered'>
        <p className='title is-4 has-text-light'>
          Panel Overview
        </p>
        <br></br>
        <p className='heading has-text-light'></p>
      </div>
      <div className='level-item has-text-centered'>
        <div>
          <p className='heading has-text-light'>Total events - 7 days</p>
          <p className='title is-4 has-text-light'>{eventVolumeLast7days.toLocaleString()}</p>
        </div>
      </div>
      <div className='level-item has-text-centered'>
        <div>
          <p className='heading has-text-light'>Qubed events - 7 days</p>
          <p className='title is-4 has-text-light'>{qubedVolumeLast7days.toLocaleString()}</p>
        </div>
      </div>
      <div className='level-item has-text-centered'>
        <div>
          <p className='heading has-text-light'>Perceived CR - 7 days</p>
          <p className='title is-4 has-text-light'>{(completionRate * 100).toFixed(2)}%</p>
        </div>
      </div>
    </nav>
  )
}

export default OverviewStatsLevel