import ConnectionChange from '../../../../types/Panelist/ConnectionChange';
import Pagination from '../../../Pagination/Pagination';
import { useEffect, useState } from 'react';
import { ConnectionTab } from '../ConnectionTab/ConnectionTab';
import { formatDateTimestamp, timePassedfromDate } from '../../../../utils/FormatDateTimestamp';
import NoDataMesasge from '../../../NoDataMessage/NoDataMessage';
import { sortByCreatedAt, SortOrder } from '../../../../utils/SortByCreatedAt';
import * as HiIcons from 'react-icons/hi';

const ConnectionChangeInstance: React.FC<{
  connectionChange: ConnectionChange,
  previousChangeTimestamp: string | undefined,
  index: number,
  setModal: React.Dispatch<React.SetStateAction<number>> }> = ({ connectionChange, previousChangeTimestamp, index, setModal }) => {
  return (
    <>
      <tr onClick={ () => {setModal(index)}}>
        <td>{formatDateTimestamp(connectionChange?.createdAt) || '-'}</td>
        <td>{connectionChange.ip_address || '-'}</td>
        <td>{connectionChange.connection?.country?.name || '-'}</td>
        <td>{connectionChange.connection?.isp || '-'}</td>
        <td>{connectionChange.connection?.organisation || '-'}</td>
        <td>{timePassedfromDate(connectionChange.createdAt, previousChangeTimestamp)?.replace('ago', 'before') || '-'}</td>
      </tr>
    </>
  );
}

const ConnectionChangeInstanceModal: React.FC<{
  connectionChange: ConnectionChange,
  isModalActive: boolean,
  setModal: React.Dispatch<React.SetStateAction<number>> }> = ({ connectionChange, isModalActive, setModal }) => {
  return (
    <div className={ isModalActive ? 'modal is-active': 'modal'}>
      <div onClick={ () => {setModal(-1)}} className='modal-background'></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Connection Change Details</p>
          <button onClick={ () => {setModal(-1)}} className='delete' aria-label='close'></button>
        </header>
        <section className='modal-card-body'>
          <nav className='panel is-dark'>
            <p className='panel-heading text-centered'>
              Connection
            </p>
            <ConnectionTab {...connectionChange?.connection}/>
          </nav>
        </section>
        <footer className='modal-card-foot'></footer>
      </div>
    </div>
  );
}

const ConnectionChangesPage: React.FC<{
  pageEntries: Array<ConnectionChange> | undefined,
  pageStartIndex: number, selectedModal: number,
  nextChunkFirstEntryTimestamp: string | undefined,
  setSelectedModal: React.Dispatch<React.SetStateAction<number>>,
  sortOrderState: string,
  switchSortOrder: () => void }> = ({ pageEntries, pageStartIndex, selectedModal, nextChunkFirstEntryTimestamp, setSelectedModal, sortOrderState, switchSortOrder }) => {
  let connectionChangeElements: JSX.Element[] = [];
  let connectionChangeModals: JSX.Element[] = [];

  pageEntries?.forEach((connectionChange: any, index: number) => {
    connectionChangeElements?.push(
      <ConnectionChangeInstance
        key={index + pageStartIndex}
        index={index + pageStartIndex}
        connectionChange={connectionChange}
        previousChangeTimestamp={pageEntries[sortOrderState === 'desc' ? index + 1 : index - 1]?.createdAt || nextChunkFirstEntryTimestamp}
        setModal={setSelectedModal}/>
      );
    connectionChangeModals?.push(
      <ConnectionChangeInstanceModal
      key={index + pageStartIndex}
      connectionChange={connectionChange}
      isModalActive={(index + pageStartIndex) === selectedModal}
      setModal={setSelectedModal}/>
    );
  });

  return (
    <div className='panel-block is-size-7'>
      <table className='table is-bordered is-hoverable is-fullwidth table-hover-inspect-cursor'>
        <tbody>
          <tr className='table-header-row'>
            <th className='inspect-cell' onClick={ () => { switchSortOrder(); }} title='Sort'>timestamp {sortOrderState === 'desc' ? <HiIcons.HiSortDescending/> : <HiIcons.HiSortAscending/>}</th>
            <th>ip</th>
            <th>country</th>
            <th>isp</th>
            <th>organisation</th>
            <th>previous change</th>
          </tr>
          {connectionChangeElements}
        </tbody>
      </table>
      {connectionChangeModals}
    </div>
  )
}

const ConnectionChangesTab: React.FC<{
  connectionChanges: Array<ConnectionChange> | undefined }> = ({ connectionChanges = [] }) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const [selectedModal, setSelectedModal] = useState(-1);
  const [sortOrderState, setSortOrderState] = useState('desc' as SortOrder);
  const sortedConnectionChanges = sortByCreatedAt(connectionChanges, sortOrderState) as Array<ConnectionChange>;
  
  const chunkSize = 15;
  const connectionChangesChunk = sortedConnectionChanges?.slice(chunkSize*selectedPageIndex, chunkSize*selectedPageIndex + chunkSize);
  const connectionChangesChunkStartIndex = chunkSize*selectedPageIndex;

  // need to pass this value along for calculating time window between last chunk entry and next chunk start
  const nextChunkFirstEntryTimestamp : string | undefined = sortedConnectionChanges?.[sortOrderState === 'desc' ? (connectionChangesChunkStartIndex + chunkSize) : (connectionChangesChunkStartIndex - 1)]?.createdAt;

  useEffect(() => {
    setSelectedPageIndex(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrderState]);

  const switchSortOrder = () => {
    sortOrderState === 'desc' ? setSortOrderState('asc') : setSortOrderState('desc');
  }

  return (
    <>
      {
        sortedConnectionChanges?.length === 0 ? 
        <div className='panel-block'>
          <NoDataMesasge/>
        </div> :
        <>
          <p className='heading centered-paragraph-heading'>Total Entries: {sortedConnectionChanges.length}</p>
          <ConnectionChangesPage
            pageEntries={connectionChangesChunk}
            pageStartIndex={connectionChangesChunkStartIndex}
            selectedModal={selectedModal} setSelectedModal={setSelectedModal}
            nextChunkFirstEntryTimestamp={nextChunkFirstEntryTimestamp}
            sortOrderState={sortOrderState}
            switchSortOrder={switchSortOrder}/>
          <div className='panel-block'>
            <Pagination
              pagesAmount={Math.ceil(( sortedConnectionChanges?.length || 0 )/chunkSize)}
              selectedPageIndex={selectedPageIndex}
              setSelectedPageIndex={setSelectedPageIndex}/>
          </div>
        </>
      }
    </>
  );
}

export { ConnectionChangesTab };
