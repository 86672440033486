

import { QubedOutcome } from '../../../../types/Panelist/QubedOutcome';
import OutcomeReasonList from './OutcomeReasonList';

const OutcomeTable: React.FC<{
  qubedOutcome: QubedOutcome
  isLatestOutcome?: boolean
}> = ({ qubedOutcome, isLatestOutcome }) => {
  const { allReasons } = qubedOutcome;

  return (
    <table className='table is-bordered is-hoverable is-striped is-fullwidth'>
      <tbody>
        {
          !isLatestOutcome ?
          <tr>
          <th>panelist quality:</th>
          <td style={{padding: '16px 25px'}}>{qubedOutcome?.qubed_api_response?.user_quality}</td>
        </tr>:
        <></>
        }
        
        {
          !!allReasons && allReasons?.length !== 0 ?
            <tr>
              <th>reason:</th>
              {<OutcomeReasonList allReasons={allReasons} />}
            </tr>
            :
            <></>
        }
      </tbody>
    </table>
  )
}

export { OutcomeTable };
