const NoDataMessage = ({ message }: { message?: string }): JSX.Element => {
  return (
    <article className='message is-dark'>
      <div className='message-body'>
        {message ? message : 'There is no data from the past 2 weeks to show.'}
      </div>
    </article>
  );
}

export default NoDataMessage;
