import OutcomeChange from '../../../../types/Panelist/OutcomeChange';
import Pagination from '../../../Pagination/Pagination';
import { useEffect, useState } from 'react';
import { formatDateTimestamp } from '../../../../utils/FormatDateTimestamp';
import NoDataMesasge from '../../../NoDataMessage/NoDataMessage';
import { sortByCreatedAt, SortOrder } from '../../../../utils/SortByCreatedAt';
import * as HiIcons from 'react-icons/hi';
import OutcomeReasonList from '../Components/OutcomeReasonList';


const OutcomeChangeInstance: React.FC<{
  outcomeChange: OutcomeChange
}> = ({ outcomeChange }) => {
  return (
    <tr>
      <td style={{ padding: '22px 16px' }}>{formatDateTimestamp(outcomeChange?.createdAt) || '-'}</td>
      <td style={{ padding: '22px 16px' }}>{outcomeChange?.outcome?.qubed_api_response?.user_quality || '-'}</td>
      {<OutcomeReasonList allReasons={outcomeChange?.outcome?.allReasons} />}
    </tr>
  );
}

const OutcomeChangesPage: React.FC<{
  pageEntries: Array<OutcomeChange> | undefined,
  pageStartIndex: number,
  sortOrderState: string,
  switchSortOrder: () => void
}> = ({ pageEntries, pageStartIndex, sortOrderState, switchSortOrder }) => {
  let outcomeChangeElements: JSX.Element[] = [];
  pageEntries?.forEach((outcomeChange: any, index: number) => {
    outcomeChangeElements?.push(
      <OutcomeChangeInstance
        key={index + pageStartIndex}
        outcomeChange={outcomeChange}
      />)
  });

  return (
    <div className='panel-block is-size-7'>
      <table className='table is-bordered is-hoverable is-fullwidth'>
        <tbody>
          <tr className='table-header-row'>
            <th className='inspect-cell' onClick={() => { switchSortOrder(); }} title='Sort'>timestamp {sortOrderState === 'desc' ? <HiIcons.HiSortDescending /> : <HiIcons.HiSortAscending />}</th>
            <th>panelist quality</th>
            <th>reason</th>
          </tr>
          {outcomeChangeElements}
        </tbody>
      </table>
    </div>
  )
}

const OutcomeChangesTab: React.FC<{
  outcomeChanges: Array<OutcomeChange> | undefined
}> = ({ outcomeChanges = [] }) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const [sortOrderState, setSortOrderState] = useState('desc' as SortOrder);
  const sortedOutcomeChanges = sortByCreatedAt(outcomeChanges, sortOrderState) as Array<OutcomeChange>;

  const chunkSize = 15;
  const activityChunk = sortedOutcomeChanges?.slice(chunkSize * selectedPageIndex, chunkSize * selectedPageIndex + chunkSize);
  const activityChunkStartIndex = chunkSize * selectedPageIndex

  useEffect(() => {
    setSelectedPageIndex(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrderState]);

  const switchSortOrder = () => {
    sortOrderState === 'desc' ? setSortOrderState('asc') : setSortOrderState('desc');
  }

  return (
    <>
      {
        sortedOutcomeChanges?.length === 0 ?
          <div className='panel-block'>
            <NoDataMesasge />
          </div> :
          <>
            <p className='heading centered-paragraph-heading'>Total Entries: {sortedOutcomeChanges.length}</p>
            <OutcomeChangesPage
              pageEntries={activityChunk}
              pageStartIndex={activityChunkStartIndex}
              sortOrderState={sortOrderState}
              switchSortOrder={switchSortOrder}
            />
            <div className='panel-block'>
              <Pagination
                pagesAmount={Math.ceil((sortedOutcomeChanges?.length || 0) / chunkSize)}
                selectedPageIndex={selectedPageIndex}
                setSelectedPageIndex={setSelectedPageIndex} />
            </div>
          </>
      }
    </>
  );
}

export { OutcomeChangesTab };
