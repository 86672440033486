import React from 'react'
import ReactECharts from 'echarts-for-react'
import EventVolumeTimeseriesDataPoint from '../../../../types/timeseries/EventVolumeTimeseriesDataPoint';

const DailyEventVolumeChart: React.FC<{
  dailyEventVolumeState: EventVolumeTimeseriesDataPoint[] }> = ({ dailyEventVolumeState }) => {
  const chartData = dailyEventVolumeState.map(({eventCount}) => (eventCount));
  const chartLabels = dailyEventVolumeState.map(({_id}) => (new Date(_id.date).getDate() + '/' + (new Date(_id.date).getMonth() + 1 )));
  
  const options = {
    grid: { top: 50, right: 8, bottom: 24, left: 80 },
    xAxis: {
      type: 'category',
      data: chartLabels,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: chartData,
        type: 'line',
        smooth: false,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
    title: {
      text: 'Total Number of Events per Day'
    }
  };

  return (
    <section className='section'>
      <ReactECharts
        option={options}
        theme={'vintage'}/>
    </section>
  )
}

export default DailyEventVolumeChart;
