import { useState, useEffect } from 'react';
import ReviewRequest from '../../../types/ReviewRequest';
import Pagination from '../../Pagination/Pagination';
import * as FaIcons from 'react-icons/fa';
import PanelistProfile from '../../PanelistProfile/PanelistProfile';
import ManualReviewForm from '../../ManualReview/ManualReviewForm';
import { useFetch } from '../../../hooks/useFetch';
import PanelistEntry from '../../../types/Panelist/PanelistEntry';
import PanelistActivity from '../../../types/Panelist/PanelistActivity';
import DemographicChange from '../../../types/Panelist/DemographicChange';
import ConnectionChange from '../../../types/Panelist/ConnectionChange';
import OutcomeChange from '../../../types/Panelist/OutcomeChange';
import Loading from '../../Helpers/loading';
import { useDiscardReviewRequest } from '../requests/useDiscardReviewRequest';
import { formatDateTimestamp } from '../../../utils/FormatDateTimestamp';

const ProcessReviewRequestModal: React.FC<{
  reviewRequest: ReviewRequest,
  index: number,
  isModalActive: boolean,
  searchPanelistLoadingState: boolean,
  panelistEntryState: PanelistEntry,
  panelistActivityState: Array<PanelistActivity>,
  demographicChangesState: Array<DemographicChange>,
  connectionChangesState: Array<ConnectionChange>,
  outcomeChangesState: Array<OutcomeChange>,
  setModal: React.Dispatch<React.SetStateAction<boolean>>,
  onSuccessfulReviewRequestProcess: () => Promise<void>,
  onFailedReviewRequestProcess: () => Promise<void>}> = ({ reviewRequest, index, isModalActive, panelistEntryState, panelistActivityState, demographicChangesState, connectionChangesState, outcomeChangesState, searchPanelistLoadingState, setModal, onSuccessfulReviewRequestProcess, onFailedReviewRequestProcess }) => {

  const [modalWrapper, setModalWrapper] = useState(0);

  useEffect(() => {
    if (modalWrapper === -1) {
      setModal(false);
    }
    setModalWrapper(0);
  }, [modalWrapper, setModal]);

  return (
    <div className={ isModalActive ? 'modal is-active': 'modal'}>
      <div onClick={ () => {setModal(false)}} className='modal-background'></div>
      <div className='modal-card' style={{ width: '95%', height: '95%' }}>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Process Review Request</p>
          <button onClick={ () => {setModal(false)}} className='delete' aria-label='close'></button>
        </header>
        <section className='modal-card-body'>
          <div className='container is-max-desktop panelists'>
          <section className='section'>
              {searchPanelistLoadingState ? <Loading/> :
              <PanelistProfile 
                panelistEntry={panelistEntryState} 
                panelistActivity={panelistActivityState} 
                demographicChanges={demographicChangesState} 
                connectionChanges={connectionChangesState} 
                outcomeChanges={outcomeChangesState} />}  
            </section>
            <section className='section'>
              <ManualReviewForm
                componentId={index}
                panelistId={reviewRequest?.panelist_id}
                setReviewModal={setModalWrapper}
                successCallback={onSuccessfulReviewRequestProcess}
                failCallback={onFailedReviewRequestProcess} reviewRequestId={reviewRequest._id}/>
            </section>
          </div>
        </section>
        <footer className='modal-card-foot'></footer>
      </div>
    </div>
  );

}

const DiscardReviewRequestModal: React.FC<{
  reviewRequest: ReviewRequest,
  isModalActive: boolean,
  setModal: React.Dispatch<React.SetStateAction<boolean>>,
  onSuccessfulReviewRequestDiscard: () => Promise<void>,
  onFailedReviewRequestDiscard: () => Promise<void>}> = ({ reviewRequest, isModalActive, setModal, onSuccessfulReviewRequestDiscard, onFailedReviewRequestDiscard }) => {
  const { discardReviewRequest } = useDiscardReviewRequest();
  
  const discardRequest = async (event: any) => {
    setDiscardReviewRequestLoadingState(true);

    discardReviewRequest(reviewRequest.panelist_id.split(':')[0], reviewRequest._id).catch(() => {
      onFailedReviewRequestDiscard();
      setDiscardReviewRequestLoadingState(false) }).then( () => {
      onSuccessfulReviewRequestDiscard();
      setDiscardReviewRequestLoadingState(false); });

    event.preventDefault();
  };

  const [discardReviewRequestLoadingState, setDiscardReviewRequestLoadingState] = useState(false);

  return ( 
    <div className={ isModalActive ? 'modal is-active': 'modal'}>
      <div onClick={ () => {setModal(false)}} className='modal-background'></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Discard Review Request</p>
          <button onClick={ () => {setModal(false)}} className='delete' aria-label='close'></button>
        </header>
        <section className='modal-card-body'>
          <form onSubmit={discardRequest}>
            <label className="label">Are you sure you want to discard this review request?</label>
            <br/>
            <div className="field is-grouped">
              <div className="control">
                <button type='submit' className={ discardReviewRequestLoadingState ? "button is-danger is-loading" : "button is-danger" }>Confirm</button>
              </div>
              <div className="control">
                <button onClick={ () => {setModal(false)}} type="button" className="button is-link is-light">Cancel</button>
              </div>
            </div> 
          </form>
        </section>
        <footer className='modal-card-foot'></footer>
      </div>
    </div>
  );

}

const UnhandledReviewRequest: React.FC<{
  reviewRequest: ReviewRequest,
  index: number,
  setReviewRequestActionSelectedState: React.Dispatch<React.SetStateAction<string>>,
  openReviewRequestProcessModal: (reviewRequest: ReviewRequest, reviewRequestIndex: number) => Promise<void>,
  openReviewRequestDeleteModal: (reviewRequest: ReviewRequest, reviewRequestIndex: number) => Promise<void>}> = ({ reviewRequest, index, setReviewRequestActionSelectedState, openReviewRequestProcessModal, openReviewRequestDeleteModal }) => {

  return (
    <>
      <tr>
        <td>{formatDateTimestamp(reviewRequest?.createdAt) || '-'}</td>
        <td>{reviewRequest.panelist_id.split(':')[1]}</td>
        <td>{reviewRequest.is_fraudulent.toString()}</td>
        <td>{reviewRequest.requested_by}</td>
        <td>{JSON.stringify(reviewRequest.warnings) || '-'}</td>
        <td>{reviewRequest.comment || '-'}</td>
        <td style={{border: 'none'}}><button onClick={ () => {openReviewRequestProcessModal(reviewRequest, index); setReviewRequestActionSelectedState('process'); }} className='button is-outlined is-small is-success'><FaIcons.FaClipboardList/></button></td>
        <td style={{border: 'none'}}><button onClick={ () => {openReviewRequestDeleteModal(reviewRequest, index); setReviewRequestActionSelectedState('discard'); }} className='button is-outlined is-small is-danger'><FaIcons.FaTrashAlt/></button></td>
      </tr>
    </>
  );
}

const UnhandledReviewRequestsPage: React.FC<{ 
  pageEntries: Array<ReviewRequest> | undefined,
  pageStartIndex: number,
  onReviewRequestProcess: () => Promise<void>,
  onReviewRequestDiscard: () => Promise<void>,
  onFailedReviewRequestAction: () => Promise<void>}> = ({ pageEntries, pageStartIndex, onReviewRequestProcess, onReviewRequestDiscard, onFailedReviewRequestAction }) => {
  let reviewRequestElements: JSX.Element[] = [];

  const [panelistEntryState, setPanelistEntryState] = useState({} as PanelistEntry);
  const [panelistActivityState, setPanelistActivityState] = useState([] as Array<PanelistActivity>);
  const [demographicChangesState, setDemographicChangesState] = useState([] as Array<DemographicChange>);
  const [connectionChangesState, setConnectionChangesState] = useState([] as Array<ConnectionChange>);
  const [outcomeChangesState, setOutcomeChangesState] = useState([] as Array<OutcomeChange>);
  const [searchPanelistLoadingState, setSearchPanelistLoadingState] = useState(false);
  const [reviewRequestActionSelectedState, setReviewRequestActionSelectedState] = useState('');
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedReviewRequest, setSelectedReviewRequest] = useState({} as ReviewRequest);
  const [selectedReviewRequestIndex, setSelectedReviewRequestIndex] = useState(-1);
  const { callProtectedEndpoint } = useFetch();
  
  const getPanelist = (async (reviewRequest: ReviewRequest) => {
    setSearchPanelistLoadingState(true);

    Promise.all([callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${reviewRequest?.panel}/panelists/${reviewRequest?.panelist_id.split(':')[1]}`), 
      callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${reviewRequest?.panel}/panelists/${reviewRequest?.panelist_id.split(':')[1]}/activity`),
      callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${reviewRequest?.panel}/panelists/${reviewRequest?.panelist_id.split(':')[1]}/demographic_changes`),
      callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${reviewRequest?.panel}/panelists/${reviewRequest?.panelist_id.split(':')[1]}/connection_changes`),
      callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${reviewRequest?.panel}/panelists/${reviewRequest?.panelist_id.split(':')[1]}/outcome_changes`)]).then( results =>
    {
      const [panelistEntry, activity, demographicChanges, connectionChanges, outcomeChanges] = results;

      // Reverse order of panelist reviews. (Latest reviews should come first)
      panelistEntry?.panelist?.reviews?.reverse();

      setPanelistEntryState({...panelistEntry});
      setPanelistActivityState(activity);
      setDemographicChangesState(demographicChanges);
      setConnectionChangesState(connectionChanges);
      setOutcomeChangesState(outcomeChanges);

      setSearchPanelistLoadingState(false);
    });
  });

  const openReviewRequestProcessModal = (async (reviewRequest: ReviewRequest, reviewRequestIndex: number) => {
    setModalOpened(true);
    setSelectedReviewRequestIndex(reviewRequestIndex);
    setSelectedReviewRequest(reviewRequest);
    await getPanelist(reviewRequest);
  });

  const openReviewRequestDiscardModal = (async (reviewRequest: ReviewRequest, reviewRequestIndex: number) => {
    setModalOpened(true);
    setSelectedReviewRequestIndex(reviewRequestIndex);
    setSelectedReviewRequest(reviewRequest);
  });

  pageEntries?.forEach((reviewRequest: any, index: number) => {
    reviewRequestElements?.push(
    <UnhandledReviewRequest 
      key={index + pageStartIndex}
      index={index + pageStartIndex}
      reviewRequest={reviewRequest}
      setReviewRequestActionSelectedState={setReviewRequestActionSelectedState}
      openReviewRequestProcessModal={openReviewRequestProcessModal}
      openReviewRequestDeleteModal={openReviewRequestDiscardModal}/>)
  });

  return (
    <div className='panel-block is-size-7'>
      <table className='table is-bordered is-hoverable is-fullwidth'>
        <tbody>
          <tr className='table-header-row'>
            <th>timestamp</th>
            <th>panelist id</th>
            <th>is fraudulent</th>
            <th>requested by</th>
            <th>warnings</th>
            <th>comment</th>
          </tr>
          {reviewRequestElements}
        </tbody>
      </table>
      <div className='panel-block'>
        <div className={ modalOpened ? 'modal is-active': 'modal'}>
          {reviewRequestActionSelectedState === 'process' && 
            <ProcessReviewRequestModal 
              reviewRequest={selectedReviewRequest}
              index={selectedReviewRequestIndex}
              isModalActive={modalOpened}
              searchPanelistLoadingState={searchPanelistLoadingState}
              panelistEntryState={panelistEntryState}
              panelistActivityState={panelistActivityState}
              demographicChangesState={demographicChangesState}
              connectionChangesState={connectionChangesState}
              outcomeChangesState={outcomeChangesState}
              setModal={setModalOpened}
              onSuccessfulReviewRequestProcess={onReviewRequestProcess}
              onFailedReviewRequestProcess={onFailedReviewRequestAction}/>}
          {reviewRequestActionSelectedState === 'discard' && 
            <DiscardReviewRequestModal
              reviewRequest={selectedReviewRequest}
              isModalActive={modalOpened}
              setModal={setModalOpened}
              onSuccessfulReviewRequestDiscard={onReviewRequestDiscard}
              onFailedReviewRequestDiscard={onFailedReviewRequestAction}/>}
        </div>
      </div>
    </div>
  )
}

const UnhandledRequestsTab: React.FC<{
  reviewRequests: Array<ReviewRequest> | undefined,
  onReviewRequestProcess: () => Promise<void>,
  onReviewRequestDiscard: () => Promise<void>,
  onFailedReviewRequestAction: () => Promise<void>}> = ({ reviewRequests, onReviewRequestProcess, onReviewRequestDiscard, onFailedReviewRequestAction }) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  
  const chunkSize = 5;
  const activityChunk = reviewRequests?.slice(chunkSize*selectedPageIndex, chunkSize*selectedPageIndex + chunkSize);
  const activityChunkStartIndex = chunkSize*selectedPageIndex

  return (
    <>
      <UnhandledReviewRequestsPage 
        pageEntries={activityChunk}
        pageStartIndex={activityChunkStartIndex}
        onReviewRequestProcess={onReviewRequestProcess}
        onReviewRequestDiscard={onReviewRequestDiscard}
        onFailedReviewRequestAction={onFailedReviewRequestAction}/>
      <div className='panel-block'>
        <Pagination 
          pagesAmount={Math.ceil(( reviewRequests?.length || 0 )/chunkSize)}
          selectedPageIndex={selectedPageIndex}
          setSelectedPageIndex={setSelectedPageIndex}/>
      </div>
    </>
  );
}

export { UnhandledRequestsTab };
