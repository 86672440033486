
import { TabItem } from './TabItem';

const toCamelCase = (input: string) => (input.toLowerCase().replace('_', ' ').replace(/\b\w/g, (char) => char.toUpperCase()));

export const TabsBar = ({ ...props }: any) => {
    const { profileTabs, activeTab, setActiveTab } = props;
    return (
        <p className='panel-tabs'>
            {profileTabs.map(({ tabKey, tabValue }: { tabKey: string, tabValue: string }, index:any) => (
                <TabItem key={index} value={toCamelCase(tabKey)} className={activeTab === tabValue ? 'is-active' : ''} onClick={() => { setActiveTab(tabValue) }} />
            ))}
        </p>
    );

} 