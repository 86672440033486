import PanelistActivity from '../../../../types/Panelist/PanelistActivity';
import Pagination from '../../../Pagination/Pagination';
import React, { useEffect, useState } from 'react';
import { SessionTable } from './SessionTable';
import { DeviceTable } from './DeviceTable';
import { LocationTable } from './LocationTable';
import { OutcomeTable } from '../Components/OutcomeTable';
import { DemographicsTable } from '../DemographicsTab/DemographicsTable';
import { formatDateTimestamp } from '../../../../utils/FormatDateTimestamp';
import NoDataMessage from '../../../NoDataMessage/NoDataMessage';
import * as HiIcons from 'react-icons/hi';
import FilterBlock from '../../../FilterBlock/FilterBlock';
import Filter from '../../../../types/Panelist/Filter';
import FilterableField from '../../../../types/Panelist/FilterableField';
import { Item, sortByCreatedAt, SortOrder } from '../../../../utils/SortByCreatedAt';

const RecentActivityInstance: React.FC<{
  activityEntry: PanelistActivity,
  index: number,
  setModal: React.Dispatch<React.SetStateAction<number>> }> = ({ activityEntry, index, setModal }): JSX.Element => {
  return (
    <>
      <tr>
        <td className='inspect-cell' onClick={ () => { setModal(index) }} title='Inspect activity'>
          {formatDateTimestamp(activityEntry?.createdAt) || '-'}
        </td>
        <td>{activityEntry.session?.event?.name}</td>
        <td>{activityEntry.session?.survey?.status_detail}</td>
        <td>{activityEntry.session?.event?.survey_id}</td>
        <td>{activityEntry.session?.survey?.loi || '-'}</td>
        <td>{activityEntry.device?.user_agent}</td>
        <td>{activityEntry.location?.ip_address}</td>
      </tr>
    </>
  );
}

const RecentActivityInstanceModal: React.FC<{
  activityEntry: PanelistActivity,
  allReasons: Array<string>,
  isModalActive: boolean,
  setModal: React.Dispatch<React.SetStateAction<number>> }> = ({ activityEntry, isModalActive,allReasons, setModal }): JSX.Element => {
  return (
    <div className={ isModalActive ? 'modal is-active': 'modal'}>
      <div onClick={ () => {setModal(-1)}} className='modal-background'></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Activity Details</p>
          <button onClick={ () => {setModal(-1)}} className='delete' aria-label='close'></button>
        </header>
        <section className='modal-card-body'>
          <nav className='panel is-dark'>
            <p className='panel-heading text-centered'>
              Session
            </p>
            <div className='panel-block'>
              <SessionTable session={activityEntry?.session}/>
            </div>
          </nav>
          <nav className='panel is-dark'>
            <p className='panel-heading text-centered'>
              Demographics
            </p>
            <DemographicsTable demographics={activityEntry?.demographics}/>
          </nav>
          <nav className='panel is-dark'>
            <p className='panel-heading text-centered'>
              Device
            </p>
            <div className='panel-block'>
              <DeviceTable device={activityEntry?.device}/>
            </div>
          </nav>
          <nav className='panel is-dark'>
            <p className='panel-heading text-centered'>
              Location
            </p>
            <div className='panel-block'>
              <LocationTable location={activityEntry?.location}/>
            </div>
          </nav>
          <nav className='panel is-dark'>
            <p className='panel-heading text-centered'>
              Outcome
            </p>
            <div className='panel-block'>
              <OutcomeTable qubedOutcome={activityEntry?.outcome || {}}/>
            </div>
          </nav>
        </section>
        <footer className='modal-card-foot'></footer>
      </div>
    </div>
  );
}

const RecentActivityPage: React.FC<{
  pageEntries: Array<PanelistActivity> | undefined,
  allReasons: Array<string>,
  pageStartIndex: number,
  selectedModal: number,
  setSelectedModal: React.Dispatch<React.SetStateAction<number>>,
  sortOrderState: string,
  switchSortOrder: () => void }> = ({ pageEntries, allReasons,pageStartIndex, selectedModal, setSelectedModal, sortOrderState, switchSortOrder }): JSX.Element => {
  let pageActivityElements: JSX.Element[] = [];
  let pageActivityModals: JSX.Element[] = [];

  pageEntries?.forEach((activity: any, index: number) => {
    pageActivityElements?.push(<RecentActivityInstance key={index + pageStartIndex} index={index + pageStartIndex} activityEntry={activity} setModal={setSelectedModal}/>)
    pageActivityModals?.push(<RecentActivityInstanceModal key={index + pageStartIndex} activityEntry={activity} allReasons ={allReasons} isModalActive={(index + pageStartIndex) === selectedModal} setModal={setSelectedModal}/>)
  });

  return (
    <div className='panel-block is-size-7'>
      <table className='table is-bordered is-hoverable is-fullwidth'>
        <tbody>
          <tr className='table-header-row'>
            <th className='inspect-cell' onClick={ () => { switchSortOrder(); }} title='Sort'>timestamp {sortOrderState === 'desc' ? <HiIcons.HiSortDescending/> : <HiIcons.HiSortAscending/>}</th>
            <th>event name</th>
            <th>event detail</th>
            <th>survey id</th>
            <th>survey loi</th>
            <th>user agent</th>
            <th>ip</th>
          </tr>
          {pageActivityElements}
        </tbody>
      </table>
      {pageActivityModals}
    </div>
  )
}

const filterActivity = (activity: Array<PanelistActivity>, filters: Filter[]): Array<PanelistActivity> => {
  let filteredActivity: Array<PanelistActivity> = activity;

  filters?.forEach((filter: Filter) => {
    switch (filter.fieldName) {
      case 'timestamp':
        switch (filter.fieldOperator) {
          case 'is before':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.createdAt && filter?.comparisonDate && new Date(activityInstance.createdAt) < filter.comparisonDate;
            });
            break;
          case 'is after':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.createdAt && filter?.comparisonDate && new Date(activityInstance.createdAt) > filter.comparisonDate;
            });
            break;
          case 'is between':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              let date1 = filter.comparisonDateRange?.startDate;
              let date2 = filter.comparisonDateRange?.endDate;

              // check if dates have been mismathced and need to be reversed
              if (date1 && date2 && date1.getTime() > date2.getTime()) {
                date2 = filter.comparisonDateRange?.startDate;
                date1 = filter.comparisonDateRange?.endDate;
              } 

              return activityInstance?.createdAt && date1 && date2 && date1 <= new Date(activityInstance.createdAt) && new Date(activityInstance.createdAt) <= date2
            });
            break;
        }
        break;
      case 'event name':
        switch (filter.fieldOperator) {
          case 'is':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.session?.event?.name === filter.comparisonValue;
            });
            break;
          case 'is not':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.session?.event?.name !== filter.comparisonValue;
            });
            break;
          case 'contains':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.session?.event?.name?.includes(filter?.comparisonValue || '');
            });
            break;
        }
        break;
      case 'event detail':
        switch (filter.fieldOperator) {
          case 'is':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.session?.survey?.status_detail === filter.comparisonValue;
            });
            break;
          case 'is not':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.session?.survey?.status_detail !== filter.comparisonValue;
            });
            break;
          case 'contains':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.session?.survey?.status_detail?.includes(filter?.comparisonValue || '');
            });
            break;
        }
        break;
      case 'survey id':
        switch (filter.fieldOperator) {
          case 'is':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.session?.event?.survey_id === filter.comparisonValue;
            });
            break;
          case 'is not':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.session?.event?.survey_id !== filter.comparisonValue;
            });
            break;
          case 'contains':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.session?.event?.survey_id?.includes(filter?.comparisonValue || '');
            });
            break;
        }
        break;
      case 'user agent':
        switch (filter.fieldOperator) {
          case 'is':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.device?.user_agent === filter.comparisonValue;
            });
            break;
          case 'is not':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.device?.user_agent !== filter.comparisonValue;
            });
            break;
          case 'contains':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.device?.user_agent?.includes(filter?.comparisonValue || '');
            });
            break;
        }
        break;
      case 'ip':
        switch (filter.fieldOperator) {
          case 'is':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.location?.ip_address === filter.comparisonValue;
            });
            break;
          case 'is not':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.location?.ip_address !== filter.comparisonValue;
            });
            break;
          case 'contains':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return activityInstance?.location?.ip_address?.includes(filter?.comparisonValue || '');
            });
            break;
        }
        break;
      case 'survey_loi':
        switch (filter.fieldOperator) {
          case 'is more than or equal to':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return (activityInstance?.session?.survey?.loi || activityInstance?.session?.survey?.loi === 0) ? activityInstance?.session?.survey?.loi >= Number(filter.comparisonValue) : false;
            });
            break;
          case 'is less than or equal to':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return (activityInstance?.session?.survey?.loi || activityInstance?.session?.survey?.loi === 0) ? activityInstance?.session?.survey?.loi <= Number(filter.comparisonValue) : false;
            });
            break;
          case 'equals':
            filteredActivity = filteredActivity?.filter((activityInstance: PanelistActivity) => {
              return (activityInstance?.session?.survey?.loi || activityInstance?.session?.survey?.loi === 0) ? activityInstance?.session?.survey?.loi === Number(filter.comparisonValue) : false;
            });
            break;
        }
        break;
    }
  });

  return filteredActivity;
}

const RecentActivityTab: React.FC<{
  recentActivity: Array<PanelistActivity> | undefined,
  searchFilters: Array<Filter>,
  allReasons: Array<string>,
  setSearchFilters: React.Dispatch<React.SetStateAction<Array<Filter>>> }> = ({ recentActivity = [],allReasons, searchFilters, setSearchFilters }) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const [selectedModal, setSelectedModal] = useState(-1);
  const [sortOrderState, setSortOrderState] = useState('desc' as SortOrder);
  const [filteredRecentActivity, setFilteredRecentActivity] = useState(sortByCreatedAt(filterActivity(recentActivity as Array<PanelistActivity>, searchFilters) as Array<Item>, sortOrderState) as Array<PanelistActivity>);

  const chunkSize = 10;
  const activityChunk = filteredRecentActivity?.slice(chunkSize*selectedPageIndex, chunkSize*selectedPageIndex + chunkSize);
  const activityChunkStartIndex = chunkSize*selectedPageIndex

  const filterableFields = new Map<string, FilterableField>([
    ['timestamp', { possibleOperators: ['is before', 'is after', 'is between'] }],
    ['event name', { possibleOperators: ['is', 'is not', 'contains'] }],
    ['event detail', { possibleOperators: ['is', 'is not', 'contains'] }],
    ['survey id', { possibleOperators: ['is', 'is not', 'contains'] }],
    ['user agent', { possibleOperators: ['is', 'is not', 'contains'] }],
    ['ip', { possibleOperators: ['is', 'is not', 'contains'] }],
    ['survey_loi', { possibleOperators: ['is more than or equal to', 'is less than or equal to', 'equals'] }],
  ]);

  useEffect(() => {
    setFilteredRecentActivity(sortByCreatedAt(filterActivity(recentActivity as Array<PanelistActivity>, searchFilters) as Array<Item>, sortOrderState) as Array<PanelistActivity>);
    setSelectedPageIndex(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrderState]);

  const switchSortOrder = () => {
    sortOrderState === 'desc' ? setSortOrderState('asc') : setSortOrderState('desc');
  }

  return (
    <>
      <FilterBlock searchFilters={searchFilters} setSearchFilters={setSearchFilters} filterableFields={filterableFields}/>
      { 
        filteredRecentActivity?.length === 0 ? 
        <div className='panel-block'>
          <NoDataMessage/> 
        </div> :
        <>
          <p className='heading centered-paragraph-heading'>Total Entries: {filteredRecentActivity.length}</p>
          <RecentActivityPage
            pageEntries={activityChunk}
            allReasons={allReasons}
            pageStartIndex={activityChunkStartIndex}
            selectedModal={selectedModal}
            setSelectedModal={setSelectedModal}
            sortOrderState={sortOrderState}
            switchSortOrder={switchSortOrder}/>
          <div className='panel-block'>
            <Pagination
              pagesAmount={Math.ceil(( filteredRecentActivity?.length || 0 )/chunkSize)}
              selectedPageIndex={selectedPageIndex}
              setSelectedPageIndex={setSelectedPageIndex}/>
          </div>
        </> 
      }
    </>
  );
}

export { RecentActivityTab };
