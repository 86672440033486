import React, { useState, useRef } from 'react';
import { MdSearch } from 'react-icons/md';

const PanelistLookupForm: React.FC<any> = (props: any) => {
  const [panelistId, setPanelistId] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = () => {
    if (inputRef.current) {
      props.getPanelistProfile(inputRef.current.value);
      setPanelistId('');
    }
  };

  return (
    <>
      <label className='label'>Lookup a Panelist</label>
      <div className='field is-grouped'>
        <p className='control has-icons-left is-expanded'>
          <input
            ref={inputRef}
            className='input is-rounded'
            type='text'
            placeholder='Panelist ID'
            value={panelistId}
            onChange={(e) => setPanelistId(e.target.value)}
          />
          <span className='icon is-left'>
            <MdSearch />
          </span>
        </p>
        <p className='control'>
          <button
            className={`button is-link is-rounded ${props.loadingState ? 'is-loading' : ''}`}
            onClick={handleSearch}
            disabled={!panelistId}
          >
            Search
          </button>
        </p>
      </div>
    </>
  );
};

export default PanelistLookupForm;
