import Pagination from '../../../Pagination/Pagination';
import { useState } from 'react';
import DemographicChange from '../../../../types/Panelist/DemographicChange';
import { DemographicsTable } from '../DemographicsTab/DemographicsTable';
import { formatDateTimestamp } from '../../../../utils/FormatDateTimestamp';
import { DemographicsComparison } from '../../../DemographicsChangeComparison/DemographicsComparison';
import * as MdIcons from 'react-icons/md';
import NoDataMesasge from '../../../NoDataMessage/NoDataMessage';

const DemographicChangeInstance: React.FC<{
  demographicChange: DemographicChange,
  index: number,
  isModalActive: boolean,
  setModal: React.Dispatch<React.SetStateAction<number>> }> = ({ demographicChange, index, isModalActive, setModal }) => {
  return (
    <div className='panel-block'>
      <div className='card'>
        <footer className='card-footer'>
          <a onClick={ () => {setModal(index)}} className='card-footer-item'><span><MdIcons.MdOutlineManageSearch/> {formatDateTimestamp(demographicChange?.createdAt)}</span></a>
        </footer>
      </div>

      <div className={ isModalActive ? 'modal is-active': 'modal'}>
        <div onClick={ () => {setModal(-1)}} className='modal-background'></div>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title'>Demographic Change Details</p>
            <button onClick={ () => {setModal(-1)}} className='delete' aria-label='close'></button>
          </header>
          <section className='modal-card-body'>
            <nav className='panel is-dark'>
              <p className='panel-heading text-centered'>
                Demographics
              </p>
              <DemographicsTable demographics={demographicChange?.demographics}/>
            </nav>
          </section>
          <footer className='modal-card-foot'></footer>
        </div>
      </div>
    </div>
  );
}

const LatestDemographicChangeComparison: React.FC<{
  latestDemographicsChange: DemographicChange | undefined,
  previousDemographicsChange: DemographicChange | undefined }> = ( ({ latestDemographicsChange, previousDemographicsChange }) => {
  return (
    <div className='panel-block'>
      <div className='card'>
        <div className='card-content is-size-7'>
          <DemographicsComparison
            currentDemographics={latestDemographicsChange?.demographics}
            currentDemographicsTimestamp={formatDateTimestamp(latestDemographicsChange?.createdAt) as string}
            previousDemographics={previousDemographicsChange?.demographics}
            previousDemographicsTimestamp={formatDateTimestamp(previousDemographicsChange?.createdAt) as string}/>
        </div>
      </div>
    </div>
  );
});

const DemographicChangesPage: React.FC<{
  pageEntries: Array<DemographicChange> | undefined
  pageStartIndex: number,
  selectedModal: number,
  setSelectedModal: React.Dispatch<React.SetStateAction<number>> }> = ({ pageEntries, pageStartIndex, selectedModal, setSelectedModal }) => {
  let demographicChangeElements: JSX.Element[] = [];

  pageEntries?.forEach((demographicChange: any, index: number) => {
    demographicChangeElements?.push(
      <DemographicChangeInstance 
        key={index + pageStartIndex}
        index={index + pageStartIndex}
        demographicChange={demographicChange}
        isModalActive={(index + pageStartIndex) === selectedModal}
        setModal={setSelectedModal}/>)
  });

  return (
    <>
      {demographicChangeElements}
    </>
  )
}

const DemographicChangesTab: React.FC<{
  demographicChanges: Array<DemographicChange> | undefined }> = ({ demographicChanges = [] }) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const [selectedModal, setSelectedModal] = useState(-1);

  const latestChanges = demographicChanges?.slice(0, 2);
  const olderChanges = demographicChanges?.slice(2);
  
  const chunkSize = 5;
  const activityChunk = olderChanges?.slice(chunkSize*selectedPageIndex, chunkSize*selectedPageIndex + chunkSize);
  const activityChunkStartIndex = chunkSize*selectedPageIndex

  return (
    <>
      { demographicChanges?.length === 0 ?
        <div className='panel-block'>
          <NoDataMesasge/>
        </div> :
        <>
          <LatestDemographicChangeComparison
            latestDemographicsChange={latestChanges?.[0]}
            previousDemographicsChange={latestChanges?.[1]}/>
          <DemographicChangesPage
            pageEntries={activityChunk}
            pageStartIndex={activityChunkStartIndex}
            selectedModal={selectedModal}
            setSelectedModal={setSelectedModal}/>
          <div className='panel-block'>
            <Pagination pagesAmount={Math.ceil((olderChanges?.length || 0)/chunkSize)} selectedPageIndex={selectedPageIndex} setSelectedPageIndex={setSelectedPageIndex}/>
          </div>
        </>
      }
    </>
  );
}

export { DemographicChangesTab };
