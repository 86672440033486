import Device from '../../../../types/Panelist/Device';

const DeviceTable: React.FC<{
  device: Device | null | undefined }> = ({ device }) => {
  return (
    <div className='panel-block'>
      <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <tbody>
          <tr>
            <td>cpu architecture:</td>
            <td>{device?.cpu_architecture || '-'}</td>
          </tr>
          <tr>
            <td>model:</td>
            <td>{device?.model || '-'}</td>
          </tr>
          <tr>
            <td>type:</td>
            <td>{device?.type || '-'}</td>
          </tr>
          <tr>
            <td>user agent:</td>
            <td>{device?.user_agent || '-'}</td>
          </tr>
          <tr>
            <td>vendor:</td>
            <td>{device?.vendor || '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <thead>
          <tr>
            <th colSpan={2}>Browser</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>name:</td>
            <td>{device?.browser?.name || '-'}</td>
          </tr>
          <tr>
            <td>version:</td>
            <td>{device?.browser?.version || '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <thead>
          <tr>
            <th colSpan={2}>Engine</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>name:</td>
            <td>{device?.engine?.name || '-'}</td>
          </tr>
          <tr>
            <td>version:</td>
            <td>{device?.engine?.version || '-'}</td>
          </tr>
        </tbody>
        </table>
        <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <thead>
          <tr>
            <th colSpan={2}>OS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>name:</td>
            <td>{device?.os?.name || '-'}</td>
          </tr>
          <tr>
            <td>version:</td>
            <td>{device?.os?.version || '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export { DeviceTable };
