import ReactECharts from 'echarts-for-react'
import QubedEventVolumeTimeseriesDataPoint from '../../../../types/timeseries/QubedEventVolumeTimeseriesDataPoint';

const HourlyPanelistFlagVolumeChart = ( { hourlyPanelistFlagVolumeState }:{ hourlyPanelistFlagVolumeState: QubedEventVolumeTimeseriesDataPoint[] } ) => {
  const chartData = hourlyPanelistFlagVolumeState.map(({outcomeCount}) => (outcomeCount));
  const chartLabels = hourlyPanelistFlagVolumeState.map(({_id}) => (new Date(_id.date).getHours() + ':00'))

  const options = {
    grid: { top: 50, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: 'category',
      data: chartLabels,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: chartData,
        type: 'bar',
        smooth: false,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
    title: {
      text: 'Qubed Events Generated per Hour'
    }
  };

  return (
    <section className='section'>
      <ReactECharts option={options} theme={'vintage'}/>
    </section>
  )
}

export default HourlyPanelistFlagVolumeChart;
