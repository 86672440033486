import Location from '../../../../types/Panelist/Location';

const LocationTable: React.FC<{
  location: Location | null | undefined }> = ({ location }) => {
  return (
    <div className='panel-block'>
      <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <tbody>
          <tr>
            <td>device type:</td>
            <td>{location?.device_type || '-'}</td>
          </tr>
          <tr>
            <td>ip address:</td>
            <td>{location?.ip_address || '-'}</td>
          </tr>
          <tr>
            <td>user agent:</td>
            <td>{location?.user_agent || '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export { LocationTable };
