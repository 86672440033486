
import Home, { HomeProps } from "../Home";
import ManualReviewsTable from "./components/ManualReviewsTable";
import { ManualReview } from "../../types/ManualReview";
import { useState, useEffect } from "react";
import { useFetch } from "../../hooks/useFetch";
import { LinearProgress } from "@mui/material";


export type AuditsProps = {
    panels: string[],
    selectedPanel: string,
    setSelectedPanel: React.Dispatch<React.SetStateAction<string>>
};
const Audits = ({ ...props }: AuditsProps) => {
    const [manualReviews, setManualReviews] = useState<ManualReview | null>(null);
    const { callProtectedEndpoint } = useFetch();
    const { selectedPanel } = props;
    const fetchData = async () => {
      try {
         await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/manual_reviews`)
        .then((endpointResponse)=> {
            setManualReviews(endpointResponse);
        });
      } catch (error) {
        console.error('Error fetching manual reviews:', error);
      }
    };
  useEffect(() => {
    if(selectedPanel){
        fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPanel]);

    const homeProps: HomeProps = {
        ...props,
        children: (
            <nav className='panel is-dark' style={{  width: `${manualReviews ? 'fit-content' : '100%'}` }}>
                <p className='panel-heading text-centered'>
                    Reviews
                </p>
                {manualReviews ? <ManualReviewsTable panelistReviews={manualReviews.panelistReviews} refreshReviewList={fetchData}/> : <LinearProgress />}
            </nav>
        )
    };
    return <Home {...homeProps} />
}

export default Audits;