import { List, ListItem, ListItemText, styled } from '@mui/material';


const CustomList = styled(List)`
  font-family: Lato !important;
`;

const OutcomeReasonList = ({ allReasons }: { allReasons: Array<string> | undefined }) => {
  return (
    <td>
      <CustomList dense={true}>
        {!!allReasons &&
          allReasons?.length !== 0 &&
          allReasons.map((reason, index) => (
            <ListItem key={index}>
              <ListItemText primary={reason} />
            </ListItem>
          ))}
      </CustomList>
    </td>
  );
};

export default OutcomeReasonList;
