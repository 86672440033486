import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Divider,
  TextField,
  styled,
  Snackbar,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const StyledDialogTitle = styled(DialogTitle)`
  background-color: hsl(0deg, 0%, 96%);
  font-weight: 600;
  font-family: 'Lato';
`;

interface dialogFormProps {
  title: string;
  open: boolean;
  onClose: () => void;
  options: { name: string, value: string }[];
  onSubmitClick: (selectedOption: string, commentInputValue?: string) => Promise<any>;
  submitSuccessMessage: string;
}

const ModalForm: React.FC<dialogFormProps> = ({ open, onClose, options, onSubmitClick, title, submitSuccessMessage }) => {
  const [selectedOption, setSelectedOption] = useState<string>(title === 'Manual Review' ? options[2].name :options[0].name);
  const [comment, setComment] = useState<string>('');
  const [commentError, setCommentError] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);


  useEffect(() => {
    if (!open) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.name);
    title === 'Manual Review' &&  event.target.name === 'auto' && setCommentError(false);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
    setCommentError(false);
  };

  const reset = () => {
    setCommentError(false);
    setComment('');
    setSubmitLoading(false);
    setSelectedOption(options[0].name);
  }

  const handleSubmit = async () => {
    if(title === 'Manual Review'){
       //handle Manual Review required fields
       if(selectedOption !== 'auto' && !comment.trim()){
        setCommentError(true);
        return;
       } 
    }
    //handle audit required comment
    if (selectedOption === options[1].name && !comment.trim()) {
      setCommentError(true);
      return;
    }
    setSubmitLoading(true);
    await onSubmitClick(selectedOption, comment).then(() => {
      setSuccessMessage(submitSuccessMessage);
      setSubmitLoading(false);
    });

    reset();
    onClose();
  };

  const handleCloseSuccessMessage = () => {
    setSuccessMessage(null);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!successMessage}
        autoHideDuration={5000}
        onClose={handleCloseSuccessMessage}
      >
        <Alert style={{ marginTop: '5rem' }} severity="success" onClose={handleCloseSuccessMessage}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={false}>
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <Divider />
        <DialogContent style={{ width: '40rem' }}>
          <RadioGroup value={selectedOption} onChange={handleOptionChange}>
            {options.map((option) => (
              <FormControlLabel
                key={option.name}
                name={option.name}
                value={option.name}
                control={<Radio />}
                label={option.value}
              />
            ))}
          </RadioGroup>
          {
            title === 'Manual Review' ? 
            <TextField
            label={selectedOption === 'auto' ? 'Comments or remarks' : 'Reason'}
            variant="filled"
            fullWidth
            required={selectedOption === 'auto' ? false : true}
            multiline
            rows={2}
            margin="normal"
            onChange={handleCommentChange}
            error={commentError}
            helperText={commentError && 'Please, enter the required entry.'}/> :
            selectedOption === options[1].name && (
              <TextField
                label="Comment"
                variant="filled"
                fullWidth
                required
                multiline
                rows={2}
                margin="normal"
                onChange={handleCommentChange}
                error={commentError}
                helperText={commentError && 'Please, enter the required entry.'}
              />)
          }
        </DialogContent>
        <DialogActions className='modal-card-foot'>
          <Button onClick={onClose} >Cancel</Button>
          <LoadingButton onClick={handleSubmit}  loading={submitLoading}
           loadingPosition="center">Submit</LoadingButton>
        </DialogActions>
      </Dialog>
    </>

  );
};

export default ModalForm;
