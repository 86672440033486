import React from 'react';
import { useAccountInfo } from '../../auth/account-info';
import { USER_SINGLE_ROLES } from '../../types/User/UserRoles';

const useAuthorization = (requiredRoles: string[], requiredAuthorities: string[]) => {

  const { userRoles, userAuthorities } = useAccountInfo();

  const isAuthorized = React.useMemo(() => {
    const isUserRoleAllowed = userRoles?.some((item: string) => requiredRoles.includes(item));
    if (userRoles?.some((item: any) => Object.values(USER_SINGLE_ROLES).includes(item))) {
      return isUserRoleAllowed;
    }

    if (!userRoles || !userAuthorities) {
      return false;
    }

    // Check if the user role matches and has one of the required authorities
    return isUserRoleAllowed && userAuthorities?.some((item: string) => requiredAuthorities.includes(item))
  
  }, [requiredRoles, requiredAuthorities, userRoles, userAuthorities]);

  return {
    isAuthorized,
    userRoles,
    userAuthorities
  };
};

export default useAuthorization;