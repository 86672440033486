import QubedEventVolumeTimeseriesDataPoint from '../../../types/timeseries/QubedEventVolumeTimeseriesDataPoint';
import DailyPanelistFlagVolumeChart from '../../Charts/QubedMetrics/DailyPanelistFlagVolume/DailyPanelistFlagVolumeChart';
import HourlyPanelistFlagVolumeChart from '../../Charts/QubedMetrics/HourlyPanelistFlagVolume/HourlyPanelistFlagVolumeChart';
import Loading from '../../Helpers/loading';

const QubedMetricsContainer: React.FC<{
  hourlyPanelistFlagVolumeState: QubedEventVolumeTimeseriesDataPoint[],
  dailyPanelistFlagVolumeState: QubedEventVolumeTimeseriesDataPoint[] }> = ({ dailyPanelistFlagVolumeState, hourlyPanelistFlagVolumeState }) => {
  return (
    <div className='container'>
      <nav className="panel is-dark container is-max-desktop">
        <p className="panel-heading text-centered">
          Qubed Metrics
        </p>
        <a className="panel-block">
          { dailyPanelistFlagVolumeState.length === 0 ?  <Loading/> : <DailyPanelistFlagVolumeChart dailyPanelistFlagVolumeState={dailyPanelistFlagVolumeState}/>}
        </a>
        <a className="panel-block">
          { hourlyPanelistFlagVolumeState.length === 0 ?  <Loading/> : <HourlyPanelistFlagVolumeChart hourlyPanelistFlagVolumeState={hourlyPanelistFlagVolumeState}/> }
        </a>
      </nav>
    </div>
  )
}

export default QubedMetricsContainer;
