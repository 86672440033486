type Item = {
  [key: string]: any;
  createdAt: string;
}

type SortOrder = 'asc' | 'desc';

const sortByCreatedAt = (items: Array<Item>, sortOrder: SortOrder = 'desc'): Array<Item> => {
  const sortedItems = items.sort((a: Item, b: Item) => {
    const date1 = new Date(a.createdAt).getTime();
    const date2 = new Date(b.createdAt).getTime();
    return sortOrder === 'asc' ? date1 - date2 : date2 - date1;
  });
  return sortedItems;
};

export { sortByCreatedAt }
export type { Item, SortOrder }
