const ReviewButtons: React.FC<{
    panelistId: string | undefined,
    hasPreviousReviews: boolean,
    setPreviousReviewsModal: React.Dispatch<React.SetStateAction<boolean>>,
    setReviewModal: React.Dispatch<React.SetStateAction<boolean>> }> = ({ panelistId, hasPreviousReviews, setPreviousReviewsModal, setReviewModal }) => {
    return (
    <div className="panel-block">
      <div className="columns">
      {
        hasPreviousReviews ?
        <div className="column">
         <button onClick={ () => {setPreviousReviewsModal(true)}} className='button is-dark is-outlined is-fullwidth' disabled={panelistId ? false : true}>
           Previous Reviews
         </button>
       </div>
          :<></>
      }
        <div className="column">
          <button onClick={ () => {setReviewModal(true)}} className='button is-link is-outlined is-fullwidth' disabled={panelistId ? false : true}>
            Manual Review
          </button>
        </div>
      </div>
    </div>
    );
  };

export default ReviewButtons;