import React, { useEffect, useState } from 'react';
import QubedMetricsContainer from '../components/OverviewContainers/ChartColumnContainers/QubedMetricsContainer';
import EventMetricsContainer from '../components/OverviewContainers/ChartColumnContainers/EventMetricsContainer';
import Navbar from '../components/Navbar/Navbar';
import OverviewStatsLevel from '../components/OverviewStatsLevel/OverviewStatsLevel';
import { useFetch } from '../hooks/useFetch';
import QubedEventVolumeTimeseriesDataPoint from '../types/timeseries/QubedEventVolumeTimeseriesDataPoint';
import EventVolumeTimeseriesDataPoint from '../types/timeseries/EventVolumeTimeseriesDataPoint';
import EventDistributionByNameTimeseriesDataPoint from '../types/timeseries/EventDistributionByNameTimeseriesDataPoint';

const Overview: React.FC<{
  panels: Array<string>,
  selectedPanel: string,
  setSelectedPanel: React.Dispatch<React.SetStateAction<string>> }> = ({ panels, selectedPanel, setSelectedPanel }) => {
  // total flag volume
  const [dailyPanelistFlagVolumeState, setDailyPanelistFlagVolumeState] = useState([] as QubedEventVolumeTimeseriesDataPoint[]);
  const [hourlyPanelistFlagVolumeState, setHourlyPanelistFlagVolumeState] = useState([] as QubedEventVolumeTimeseriesDataPoint[]);

  // total event volume
  const [dailyEventVolumeState, setDailyEventVolumeState] = useState([] as EventVolumeTimeseriesDataPoint[]);
  const [hourlyEventVolumeState, setHourlyEventVolumeState] = useState([] as EventVolumeTimeseriesDataPoint[]);

  // event distribution by name 
  const [eventDistributionByNameLast7DaysState, setEventDistributionByNameLast7DaysState] = useState([] as EventDistributionByNameTimeseriesDataPoint[]);
  const [eventDistributionByNameLast24HoursState, setEventDistributionByNameLast24HoursState] = useState([] as EventDistributionByNameTimeseriesDataPoint[]);

  const { callProtectedEndpoint } = useFetch();

  useEffect(() => {
    const getDailyPanelistFlagVolume = (async () => {
      setDailyPanelistFlagVolumeState(await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/outcome-count-timeseries/bad/day`));
    });
  
    const getHourlyPanelistFlagVolume = (async () => {
      setHourlyPanelistFlagVolumeState(await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/outcome-count-timeseries/bad/hour`));
    });
  
    const getDailyEventVolume = (async () => {
      setDailyEventVolumeState(await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/event-count-timeseries/day`));
      
    });
  
    const getHourlyEventVolume = (async () => {
      setHourlyEventVolumeState(await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/event-count-timeseries/hour`));
    });
  
    const getEventDistributionByNameLast7Days = (async () => {
      setEventDistributionByNameLast7DaysState(await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/event-count-distribution-by-name-timeseries/day`));
    });
  
    const getEventDistributionByNameLast24Hours = (async () => {
      setEventDistributionByNameLast24HoursState(await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/event-count-distribution-by-name-timeseries/hour`));
    });

    getDailyPanelistFlagVolume();
    getHourlyPanelistFlagVolume();
    getDailyEventVolume();
    getHourlyEventVolume();
    getEventDistributionByNameLast7Days();
    getEventDistributionByNameLast24Hours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateCompletionRate = ( (eventDistributionByName: EventDistributionByNameTimeseriesDataPoint[]) => {
    return eventDistributionByName.filter(obj => obj._id === 'survey_complete')[0]?.count / eventDistributionByName.filter(obj => obj._id === 'survey_started')[0]?.count
  });

  return (
    <div className='fixed-navbar-padding'>
      <Navbar panels={panels} selectedPanel={selectedPanel} setSelectedPanel={setSelectedPanel}/>
      <section className='hero is-small has-background-dark'>
        <div className='hero-body'>
          <OverviewStatsLevel completionRate = { calculateCompletionRate(eventDistributionByNameLast7DaysState) } qubedVolumeLast7days= { dailyPanelistFlagVolumeState.reduce((sum, dailyEventVolumeObject) => sum + dailyEventVolumeObject.outcomeCount, 0) }
            eventVolumeLast7days = { dailyEventVolumeState.reduce((sum, dailyEventVolumeObject) => sum + dailyEventVolumeObject.eventCount, 0) }/>
        </div>
      </section>
      <section className='section'>
        <div className="columns">
          <div className='column'>
            <QubedMetricsContainer dailyPanelistFlagVolumeState={dailyPanelistFlagVolumeState} hourlyPanelistFlagVolumeState={hourlyPanelistFlagVolumeState}/>
            {/* <DemographicMetricsContainer/>
            <SurveySessionMetricsContainer/> */}
          </div>
          <div className='column'>
            <EventMetricsContainer dailyEventVolumeState={dailyEventVolumeState} hourlyEventVolumeState={hourlyEventVolumeState} 
              eventDistributionByNameLast7DaysState={eventDistributionByNameLast7DaysState} eventDistributionByNameLast24HoursState={eventDistributionByNameLast24HoursState}/>
            {/* <ConnectionMetricsContainer/> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Overview;
