import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  TableContainer
} from '@mui/material';
import {
  AccessTime,
  DateRange,
  School,
  MailOutline,
  WorkOutline,
  EmojiPeople,
  Business,
  HdrStrong,
  AlternateEmail,
  AttachMoney,
  MarkAsUnreadOutlined,
  LocationOn,
  BusinessCenter,
  WorkHistoryOutlined,
  InsertEmoticon,
  Commute,
  LocalDrink,
  People,
  SmokingRooms,
  Language,
  Paid,
  Diversity2,
  EditCalendar
} from '@mui/icons-material';
import Demographics from '../../../../types/Panelist/Demographics'

export const StyledTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  fontFamily: Lato !important;
  width: '60%';

  svg {
    margin-right: 0.5rem;
  }
`;


export const basicInformationRowsData = [
  { icon: <EditCalendar />, label: 'Creation Date', key: 'creation_date' },
  { icon: <MailOutline />, label: 'Email Address', key: 'email_address' },
  { icon: <AlternateEmail />, label: 'Cashout Method Email Address', key: 'cashout_method_email_address' },
  { icon: <AccessTime />, label: 'Age', key: 'age' },
  { icon: <DateRange />, label: 'BirthDate', key: 'dob' },
  { icon: <InsertEmoticon />, label: 'Gender', key: 'gender' },
  { icon: <School />, label: 'Education', key: 'education' },
  { icon: <EmojiPeople />, label: 'Marital Status', key: 'marital_status' },
  { icon: <AttachMoney />, label: 'Personal Income', key: 'personal_income' },
  { icon: <MarkAsUnreadOutlined />, label: 'Postal Code', key: 'postal_code' },
  { icon: <LocationOn />, label: 'Country Code', key: 'country_code' },
  { icon: <Diversity2 />, label: 'Ethnicity', key: 'ethnicity' },
  { icon: <Commute />, label: 'Has Car', key: 'has_car' },
  { icon: <Paid />, label: 'Household Income', key: 'household_income' },
  { icon: <LocalDrink />, label: 'Is Drinker', key: 'is_drinker' },
  { icon: <Language />, label: 'Is Hispanic', key: 'is_hispanic' },
  { icon: <SmokingRooms />, label: 'Is Smoker', key: 'is_smoker' },
  { icon: <People />, label: 'People in Household', key: 'people_in_household' },
];

export const employmentInfoRowsData = [
  { icon: <WorkOutline />, label: 'Job Title', key: 'job_title' },
  { icon: <Business />, label: 'Company Industry', key: 'company_industry' },
  { icon: <HdrStrong />, label: 'Company Range', key: 'company_range' },
  { icon: <BusinessCenter />, label: 'Employment Class', key: 'employment_class' },
  { icon: <WorkHistoryOutlined />, label: 'Employment Status', key: 'employment_status' },
];


const DemographicsTable: React.FC<{
  demographics: Demographics | null | undefined
}> = ({ demographics }) => {


  const SectionTable = ({ sectionTitle, rows }: { sectionTitle: string, rows: any }) => (
    <TableContainer component={Paper}>
    <Table >
      <TableBody className='table is-hoverable is-fullwidth'>
        <TableRow>
          <TableCell colSpan={2} style={{ textAlign: 'center' }}>
            <Typography variant="h6" style={{ fontFamily: 'Lato' }}>{sectionTitle}</Typography>
          </TableCell>
        </TableRow>
        {rows?.map((row: any) => (
          <TableRow key={row.key}>
            <StyledTableCell component="th" scope="row">
              {row.icon} {row.label}
            </StyledTableCell>
            <TableCell style={{ width: '40%' }}>{row.value?.toString() || '-'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  );

  const getDemographicsValue = (mapInfoList:  { icon: JSX.Element, label: string, key: string}[]) =>{
    return mapInfoList.map((item: any) => {
      const [, value] = demographics ? Object.entries(demographics).find(([key]) => key === item.key) || [] : [];
      return {
        ...item,
        value: value?.toString() || '-',
      };
    });
  }

  const employmentInformationRows = getDemographicsValue(employmentInfoRowsData);
  const basicInformationRows = getDemographicsValue(basicInformationRowsData);

  return (
    <>
      <List>
        <ListItem>
          <ListItemText>
            <SectionTable sectionTitle="Basic Information" rows={basicInformationRows} />
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>
            <SectionTable sectionTitle="Employment Information" rows={employmentInformationRows} />
          </ListItemText>
        </ListItem>
      </List>
    </>
  )
}

export { DemographicsTable };
