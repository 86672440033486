const DropdownOption: React.FC<{
  option: string }> = ({ option }): JSX.Element => {
  return (
    <option value={option}>
      {option}
    </option>
  );
};

const DropdownField: React.FC<{
  options: Array<string>,
  selectedOption: string,
  setSelectedOption: React.Dispatch<React.SetStateAction<string>> }> = ({ options, selectedOption, setSelectedOption }): JSX.Element => {
  let dropdownOptions: JSX.Element[] = [];

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  }

  options.forEach((option: string, index: number ) => {
    dropdownOptions.push(<DropdownOption key={index.toString()} option={option}/>)
  });

  return (
    <div className='select is-info is-small'>
      <select value={selectedOption} onChange={handleSelectChange}>
        {dropdownOptions}
      </select>
    </div>
  );
};

export default DropdownField;
