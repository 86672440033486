import { QubedOutcome } from '../../../../types/Panelist/QubedOutcome';
import { OutcomeTable } from '../Components/OutcomeTable';
import OutcomeReview from '../../../../types/Panelist/OutcomeReview';
import QualityCheckContainer from './QualityChecks/QualityCheckContainer';
import { USER_ROLES, USER_SINGLE_ROLES, PERMISSIONS } from '../../../../types/User/UserRoles';
import useAuthorization from '../../../Auth/useAuthorization';


const LatestOutcomeTab: React.FC<{
  qubedOutcome: QubedOutcome | undefined,
  reviews: Array<OutcomeReview>;
  duplicate_panelists: Array<string>;
  panelist_id:string;}> = ({ qubedOutcome, reviews, duplicate_panelists, panelist_id}) => {

    const { isAuthorized, userRoles } = useAuthorization([USER_ROLES.QUALITY_MANAGEMENT, ...Object.values(USER_SINGLE_ROLES)], [PERMISSIONS.EXECUTE_BASED_ON_CHECKS, PERMISSIONS.EXECUTE_ALL, PERMISSIONS.MANAGE_REVIEWS]);
    const hasDuplications =
        isAuthorized ||
        (userRoles.includes(USER_ROLES.ADVANCED_ACCESS) &&
        duplicate_panelists?.length > 0);

    
  return (
     <>
      <div className='panel-block'>
        <OutcomeTable qubedOutcome={qubedOutcome || {}} isLatestOutcome={true}/>
      </div>
        <QualityCheckContainer qubedOutcome={qubedOutcome} hasDuplications={hasDuplications} duplicatePanelists={duplicate_panelists} panelistId={panelist_id} reviews={reviews} />
     </>)
}

export { LatestOutcomeTab };
