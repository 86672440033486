import * as echarts from 'echarts'

const colorPaletteCustom = [
  '#61a0a8',
  '#919e8b',
  '#dd6b66',
  '#6e7074',
  '#d7ab82',
  '#efa18d',
  '#787464',
  '#cc7e63',
  '#724e58',
  '#4b565b'
];

const customTheme = {
  color: colorPaletteCustom,
  graph: {
    color: colorPaletteCustom
  }
}

const registerThemes = () => {
  echarts.registerTheme('vintage', customTheme);
}

export { registerThemes }