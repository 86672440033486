import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { USER_ACCESS_CONTROLS, USER_SINGLE_ROLES, USER_DATA_ROLES } from '../types/User/UserRoles';
import { useEffect, useMemo, useState } from 'react';

export const AUTH0_AUDIENCE_ROLES = `${process.env.REACT_APP_AUTH0_SHARED_TENANT}/roles`;
const AUTH0_AUDIENCE_PERMISSIONS = `${process.env.REACT_APP_AUTH0_SHARED_TENANT}/permissions`;

type AuthJwtPayload = JwtPayload & {
  [key: string]: any,
  AUTH0_AUDIENCE_ROLES: Array<USER_ACCESS_CONTROLS>,
  'https://auth0.qubed.ai/email': string,
  AUTH0_AUDIENCE_PERMISSIONS: string[],
}

export const allowedPanels = (userRoles: Array<string>) => {
  const panelRoles = [
    { name: 'qmee', roleName: USER_DATA_ROLES.QMEE_PANEL },
    { name: 'lifepoints', roleName: USER_DATA_ROLES.LIFEPOINTS_PANEL },
    { name: 'mobiworkx', roleName: USER_DATA_ROLES.MOBIWORKX_PANEL }];

  if (userRoles?.includes(USER_SINGLE_ROLES.ADMIN)) {
    return panelRoles.map(panel => panel.name);
  }

  return panelRoles.filter(panel => panel.roleName && userRoles?.includes(panel.roleName)).map(panel => panel.name);
};

export const useAccountInfo = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [userAuthorities, setUserAuthorities] = useState<string[]>([]);
  const [permittedPanels, setPermittedPanels] = useState<string[]>([]);
  
  const getUserInfo = async (): Promise<AuthJwtPayload | undefined> => {
    if (isAuthenticated && !isLoading) {
      try {
        const accessToken = await getAccessTokenSilently();
        const decodedPayload = jwt_decode<AuthJwtPayload>(accessToken);
        return decodedPayload;
      } catch (error) {
        console.error('Error fetching access token:', error);
      }
    }
    return undefined;
  };

  const fetchUserData = async () => {
    const payload = await getUserInfo();
    if (payload) {
      setUserRoles(payload[AUTH0_AUDIENCE_ROLES] || []);
      setUserAuthorities(payload[AUTH0_AUDIENCE_PERMISSIONS] || []);
      setPermittedPanels(allowedPanels(payload[AUTH0_AUDIENCE_ROLES] || []));
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  // Memoize the result to prevent unnecessary re-renders
  return useMemo(() => {
    return { userRoles, userAuthorities, permittedPanels };
  }, [userRoles, userAuthorities, permittedPanels]);

};
