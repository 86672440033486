import React from 'react'
import NavbarItem from './components/NavbarItem';
import { useAuth0 } from '@auth0/auth0-react';
import './Navbar.scss';
import { PERMISSIONS, USER_ROLES, USER_SINGLE_ROLES } from '../../types/User/UserRoles';
import useAuthorization from '../Auth/useAuthorization';
import PanelDropdown from './components/PanelDropdown';
import { Assessment, BarChart, Logout, PendingActions, Person } from '@mui/icons-material';
import { Stack } from '@mui/material';


const Navbar: React.FC<{
  panels: Array<string>,
  selectedPanel: string,
  setSelectedPanel: React.Dispatch<React.SetStateAction<string>>
}> = ({ panels, selectedPanel, setSelectedPanel }) => {

  const [isActiveBurger, setIsActiveBurger] = React.useState(false)
  const { logout } = useAuth0();
  const { isAuthorized, userRoles } = useAuthorization([USER_ROLES.QUALITY_MANAGEMENT, ...Object.values(USER_SINGLE_ROLES)], [PERMISSIONS.EXECUTE_ALL, PERMISSIONS.MANAGE_REVIEWS]);

  const navbarItems = [
    {
      key: "overviewBtn",
      title: 'Overview',
      path: '/overview',
      icon: <BarChart />,
      condition: userRoles.includes(USER_SINGLE_ROLES.ADMIN),
    },
    {
      key: "panelistsBtn",
      title: 'Panelists',
      path: '/panelists',
      icon: <Person />
    },
    {
      key: "auditsBtn",
      title: 'Audit Reviews',
      path: '/reviews',
      icon: <Assessment />,
      condition: isAuthorized,
    },
    {
      key: "reviewRequestsBtn",
      title: 'Review Requests',
      path: '/review_requests',
      icon: <PendingActions />,
      condition: isAuthorized,
    },
    {
      key: "logoutBtn",
      title: 'Logout',
      path: '#',
      icon: <Logout />,
      onClickHandler: () => logout({ returnTo: window.location.origin }),
    },
  ];

  return (
    <nav className="navbar navbar-colors is-link is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href='/panelists' >
          <img src="https://www.qubed.ai/static/media/qubed-white-horizontal.3e1dd36a.png" alt="qubed logo" />
        </a>

        <a onClick={() => {
          setIsActiveBurger(!isActiveBurger)
        }}
          role='button'
          className={`navbar-burger burger ${isActiveBurger ? 'is-active' : ''}`}
          aria-label='menu'
          aria-expanded='false'
          data-target='navbarBasicExample'
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className={`navbar-menu ${isActiveBurger ? 'is-active' : ''}`}>
        <div className="navbar-start">
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">
              <b>Panel: {selectedPanel}</b>
            </a>

            <PanelDropdown panels={panels} setSelectedPanel={setSelectedPanel} />
          </div>
        </div>

        <div>
          <Stack className="navbar-end" direction={{ xs: 'column', sm: 'row' }}
            sx={{
              maxWidth: '100%',
              marginRight: { xs: 0, sm: 4 }
            }} spacing={2}>
            {navbarItems.map((item, index) => (
              (item.condition || item.condition === undefined) && (
                <NavbarItem idx={index} {...item} />
              )
            ))}
          </Stack>
        </div>
      </div>
    </nav>
  );
}

export default Navbar