const ShortList: React.FC<{
  pageElements: JSX.Element[] }> = ({ pageElements }) => {
  return(
    <ul key='pagination-list' className="pagination-list">
      {pageElements}
    </ul> 
  );
};

const LongList: React.FC<{
  pageElements: JSX.Element[],
  currentPageIndex: number }> = ({ pageElements, currentPageIndex }) => {
  if ((currentPageIndex - 3 >= 0) && (currentPageIndex + 3 <= pageElements.length - 1)) {
    return (
      <ul key='pagination-list' className="pagination-list">
        {pageElements[0]}
        <li>
          <span className="pagination-ellipsis">&hellip;</span>
        </li>
        {pageElements.slice(currentPageIndex - 1, currentPageIndex + 2 )}
        <li>
          <span className="pagination-ellipsis">&hellip;</span>
        </li>
        {pageElements[pageElements.length - 1]}
      </ul>
    );
  }

  if ((currentPageIndex + 3 >= pageElements.length - 1)) {
    return (
      <ul key='pagination-list' className="pagination-list">
        {pageElements[0]}
        <li>
          <span className="pagination-ellipsis">&hellip;</span>
        </li>
        {pageElements.slice(pageElements.length - 4, pageElements.length)}
      </ul>
    );
  }

  return (
    <ul key='pagination-list' className="pagination-list">
      {pageElements.slice(0, 4)}
      <li>
        <span className="pagination-ellipsis">&hellip;</span>
      </li>
      {pageElements[pageElements.length - 1]}
    </ul>
  );
};

const PaginationItem: React.FC<{
  page: number,
  isActive: boolean,
  setSelectedPageIndex: React.Dispatch<React.SetStateAction<number>> }> = ({ page, isActive, setSelectedPageIndex}) => {
  return (
    <li>
      <a onClick={ () => {setSelectedPageIndex(page)}} className={ isActive ? 'is-current pagination-link': 'pagination-link'}
        aria-label={`Goto page ${page + 1}`}>{page + 1}</a>
    </li>
  );
};

const PaginationList: React.FC<{
  pages: number,
  selectedPageIndex: number,
  setSelectedPageIndex: React.Dispatch<React.SetStateAction<number>>}> = ({ pages, selectedPageIndex, setSelectedPageIndex }) => {
  let pageElements: JSX.Element[] = [];

  for(let i = 0; i<pages; i++) {
    pageElements?.push(<PaginationItem key={i.toString()} page={i} isActive={i === selectedPageIndex} setSelectedPageIndex={setSelectedPageIndex}/>)
  }

  return (
    pageElements.length > 5 ? <LongList pageElements={pageElements} currentPageIndex={selectedPageIndex}/> : <ShortList pageElements={pageElements}/>
  );
};

const Pagination: React.FC<{
  pagesAmount: number,
  selectedPageIndex: number,
  setSelectedPageIndex: React.Dispatch<React.SetStateAction<number>>}> = ({ pagesAmount, selectedPageIndex, setSelectedPageIndex }) => {

  const nextPage = () => {
    if(selectedPageIndex + 1 === pagesAmount) { 
      setSelectedPageIndex(0); 
    } else { setSelectedPageIndex(selectedPageIndex + 1); }
  }

  const previousPage = () => {
    if(selectedPageIndex - 1 < 0) { 
      setSelectedPageIndex(pagesAmount - 1); 
    } else {setSelectedPageIndex(selectedPageIndex - 1); }
  }

  return (
    <nav className="pagination" role="navigation" aria-label="pagination">
      <a onClick={ () => {previousPage()}} className="pagination-previous">Previous</a>
      <a onClick={ () => {nextPage()}} className="pagination-next">Next</a>
      <PaginationList 
        pages={pagesAmount}
        selectedPageIndex={selectedPageIndex}
        setSelectedPageIndex={setSelectedPageIndex}/>
    </nav>
  );
};

export default Pagination;
