import React, { useEffect, useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import Filter from '../../types/Panelist/Filter';
import FilterableField from '../../types/Panelist/FilterableField';
import { truncateString } from '../../utils/TruncateString';
import DropdownField from '../DropdownField/DropdownField';
import './FilterBlock.scss';

const ActiveFilter: React.FC<{
  filter: Filter,
  index: number,
  removeFilter: (filterIndex: number) => void }> = ({filter, index, removeFilter}): JSX.Element => {

  const generateFilterText = (): string => {
    switch (filter.fieldOperator) {
      case 'is before':
      case 'is after':
        return `${filter.comparisonDate?.toISOString().slice(0, 16)}`
      case 'is between':
        return `${filter.comparisonDateRange?.startDate.toISOString().slice(0, 16)} and ${filter.comparisonDateRange?.endDate.toISOString().slice(0, 16)}`
      default:
      return `${filter.comparisonValue}`
    }
  }
  return (
    <div className='control'>
      <div className='tags has-addons is-rounded'>
        <div className='tag is-info'> {`${filter.fieldName} ${filter.fieldOperator} `}
          {truncateString(generateFilterText(), 100)}
        </div>
        <div onClick={ () => {removeFilter(index)}} className='tag is-delete'></div>
      </div>
    </div>
  );
}

const ActiveFilters: React.FC<{
  areFiltersVisible: boolean,
  filters: Array<Filter>,
  removeFilter: (filterIndex: number) => void }> = ({ areFiltersVisible, filters, removeFilter }): JSX.Element => {
  let activeFilters: JSX.Element[] = [];

  filters?.forEach((filter: any, index: number) => {
    activeFilters?.push(<ActiveFilter key={index} index={index} filter={filter} removeFilter={removeFilter}/>)
  });

  return (
    <div className={`card-content ${areFiltersVisible ? 'is-visible' : 'is-hidden'}`}>
      <div className='content'>
        <div className='field is-grouped is-grouped-multiline'>
          {activeFilters.length === 0 ?
           <p style={ { color: 'grey' } }>No filters applied.</p> :
           activeFilters}
        </div>
      </div>
    </div>
  );
}

const FilterBlock: React.FC<{
  searchFilters: Array<Filter>,
  setSearchFilters: React.Dispatch<React.SetStateAction<Array<Filter>>>,
  filterableFields: Map<string, FilterableField> }> = ({searchFilters, setSearchFilters, filterableFields }): JSX.Element => {
  const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(true);
  const availableFields =  Array.from(filterableFields, entry => entry[0])
  const [selectedField, setSelectedField] = React.useState<string>(availableFields[0]);

  const availableOperators = (): Array<string> => {
    return filterableFields.get(selectedField)?.possibleOperators as Array<string>;
  }

  const [selectedOperator, setSelectedOperator] = React.useState<string>(availableOperators()[0]);

  useEffect(() => {
    setSelectedOperator(availableOperators()[0] as string);

    // reset input fields. TODO: figure out if input fields can be reset without this override
    if ((document.getElementById('comparisonValueInput') as HTMLInputElement)?.value) { (document.getElementById('comparisonValueInput') as HTMLInputElement).value = '' }
    else if ((document.getElementById('comparisonDateInput') as HTMLInputElement)?.value) { (document.getElementById('comparisonDateInput') as HTMLInputElement).value = '' }
    else if ((document.getElementById('comparisonDateRangeStartInput') as HTMLInputElement)?.value) { (document.getElementById('comparisonDateRangeStartInput') as HTMLInputElement).value = '' }
    else if ((document.getElementById('comparisonDateRangeEndInput') as HTMLInputElement)?.value) { (document.getElementById('comparisonDateRangeEndInput') as HTMLInputElement).value = '' }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField]);

  useEffect(() => {
    setAreFiltersVisible(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters]);

  
  const toggleFilterVisibility = (): void => {
    setAreFiltersVisible(!areFiltersVisible);
  };

  const addFilter = (): void => {
    const filter: Filter = {
      fieldName: selectedField,
      fieldOperator: selectedOperator,
      comparisonValue: document.getElementById('comparisonValueInput') ? (document.getElementById('comparisonValueInput') as HTMLInputElement).value : '',
      comparisonDate: (document.getElementById('comparisonDateInput') as HTMLInputElement)?.value ? new Date((document.getElementById('comparisonDateInput') as HTMLInputElement).value) : new Date(),
      comparisonDateRange: {
        startDate: (document.getElementById('comparisonDateRangeStartInput') as HTMLInputElement)?.value ? new Date((document.getElementById('comparisonDateRangeStartInput') as HTMLInputElement).value) : new Date(),
        endDate: (document.getElementById('comparisonDateRangeEndInput') as HTMLInputElement)?.value ? new Date((document.getElementById('comparisonDateRangeEndInput') as HTMLInputElement).value) : new Date(),
      },
    }
    setSearchFilters(searchFilters.concat(filter));
  }

  const removeFilter = (filterIndex: number): void => {
    let filters = searchFilters;
    filters.splice(filterIndex, 1);
    setSearchFilters([...filters]);
  }

  const resetFilters = (): void => {
    setSearchFilters([]);
  }

  return (
    <div className='panel-block'>
      <div className='card'>
        <header className='card-header'>
          <div className='card-header-title'>
            <div className='filter-block-header-item'>
              <DropdownField selectedOption={selectedField}  setSelectedOption={setSelectedField} options={availableFields} />
            </div>
            <div className='filter-block-header-item'>
              <DropdownField selectedOption={selectedOperator}  setSelectedOption={setSelectedOperator} options={ availableOperators() }/>
            </div>
              {(() => {
                switch (selectedOperator) {
                  case 'is before':
                  case 'is after':
                    return (
                      <div className='filter-block-header-item'>
                        <input id='comparisonDateInput' className="input is-info is-small" type="datetime-local" min="2000-01-01T01:00" max="3000-01-01T00:00"></input>
                      </div>
                    )
                  case 'is between':
                    return (
                      <>
                      <div className='filter-block-header-item'>
                        <input id='comparisonDateRangeStartInput' className="input is-info is-small" type="datetime-local" min="2000-01-01T01:00" max="3000-01-01T00:00"></input>
                      </div>
                      <div className='filter-block-header-item'>
                        <label className='label is-small'>and</label>
                      </div>
                      <div className='filter-block-header-item'>
                        <input id='comparisonDateRangeEndInput' className="input is-info is-small" type="datetime-local" min="2000-01-01T01:00" max="3000-01-01T00:00"></input>
                      </div>
                      </>
                    )
                  default:
                  return (
                    <div className='filter-block-header-item'>
                      <input id='comparisonValueInput' className="input is-info is-small" type="text" placeholder='comparison value' defaultValue={''}></input>
                    </div>
                  );
                }
              })()}
            <div className='filter-block-header-item'>
              <button onClick={ () => { addFilter() } } className='button is-info is-light is-small' title='Add a filter'>
                <FaIcons.FaFilter/>+
              </button>
            </div>
            <div className='filter-block-header-item'>
              <button onClick={ () => { resetFilters() } } className='button is-danger is-light is-small' title='Clear all filters'>
                <FaIcons.FaTrash/>
              </button>
            </div>
          </div>
          <button onClick={ () => { toggleFilterVisibility() }} className='card-header-icon filter-block-header-item' title={areFiltersVisible ? 'Hide filters' : 'Show filters'}>
            <span className='icon'>
              {areFiltersVisible ? <FaIcons.FaAngleUp/> : <FaIcons.FaAngleDown/>}
            </span>
          </button>
        </header>
        <ActiveFilters areFiltersVisible={areFiltersVisible} filters={searchFilters} removeFilter={removeFilter}/>
      </div>
    </div>
  );
}

export default FilterBlock;
