import { useState } from 'react';
import ReviewRequest from '../../types/ReviewRequest';
import { DiscardedRequestsTab } from './Tabs/DiscardedRequestsTab';
import { ProcessedRequestsTab } from './Tabs/ProcessedRequestsTab';
import { UnhandledRequestsTab } from './Tabs/UnhandledRequestsTab';

const ReviewRequests: React.FC<{
  requestedReviewRequests: ReviewRequest[],
  processedReviewRequests: ReviewRequest[],
  discardedReviewRequests: ReviewRequest[],
  onReviewRequestProcess: () => Promise<void>,
  onReviewRequestDiscard: () => Promise<void>,
  onFailedReviewRequestAction: () => Promise<void>}> = ({requestedReviewRequests, processedReviewRequests, discardedReviewRequests, onReviewRequestProcess, onReviewRequestDiscard, onFailedReviewRequestAction}) => {
  const [activeTab, setActiveTab] = useState('requested');

  const ReviewRequestsTabSwitch: React.FC<{
    tabChoice: string }> = ({ tabChoice }) => {
    switch (tabChoice) {
      case 'requested':
        return(
          <UnhandledRequestsTab 
            reviewRequests={requestedReviewRequests}
            onReviewRequestProcess={onReviewRequestProcess}
            onReviewRequestDiscard={onReviewRequestDiscard}
            onFailedReviewRequestAction={onFailedReviewRequestAction}/>
        );
      case 'processed':
        return(
          <ProcessedRequestsTab reviewRequests={processedReviewRequests}/>
        );
      case 'discarded':
        return(
          <DiscardedRequestsTab reviewRequests={discardedReviewRequests}/>
        );
      default:
        return (<></>);
    }
  }

  return (
      <nav className='panel is-dark'>
        <p className='panel-heading text-centered'>
          Review Requests
        </p>
        {/* eslint-disable-next-line */}
        <p className='panel-tabs'>
          <a onClick={ () => {setActiveTab('requested')}} className={ activeTab === 'requested' ? 'is-active': ''}>Requested</a>
          <a onClick={ () => {setActiveTab('processed')}} className={ activeTab === 'processed' ? 'is-active': ''}>Processed</a>
          <a onClick={ () => {setActiveTab('discarded')}} className={activeTab ==='discarded' ? 'is-active': ''}>Discarded</a>
        </p>
        <ReviewRequestsTabSwitch tabChoice={activeTab}/>
      </nav>
  )
};

export default ReviewRequests;
