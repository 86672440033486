import OutcomeReview from "./OutcomeReview";
import { PERMISSIONS } from "../User/UserRoles";
import Demographics from "./Demographics";
import { QubedOutcome } from "./QubedOutcome";
import Connection from "./Connection";

interface PanelistEntry {
  panel: string;
  panelist_id: string;
  reviews: Array<OutcomeReview>;
  duplicate_panelists: Array<string>;
  demographics?: Demographics;
  latest_qubed_outcome?: QubedOutcome;
  connection?: Connection;
  createdAt?: string;
  all_reasons?: Array<string>;
}
export enum PROFILE_TAB_NAMES {
  LATEST_OUTCOME = "latest_qubed_outcome",
  DEMOGRAPHICS = "demographics",
  CONNECTION = "connection",
  RECENT_ACTIVITY = "recent_activity",
  DEMOGRAPHIC_CHANGES = "demographic_changes",
  CONNECTION_CHANGES = "connection_changes",
  OUTCOME_CHANGES = "outcome_changes"
}

export const profile_Permission_Tabs = [
  { tabKey: "OUTCOME", tabValue: PROFILE_TAB_NAMES.LATEST_OUTCOME, permissions: [PERMISSIONS.VIEW_BASICS, PERMISSIONS.VIEW_ADVANCE_INFO, PERMISSIONS.VIEW_ALL]},
  { tabKey: "DEMOGRAPHICS", tabValue: PROFILE_TAB_NAMES.DEMOGRAPHICS, permissions: [PERMISSIONS.VIEW_ADVANCE_INFO, PERMISSIONS.VIEW_ALL]},
  { tabKey: "CONNECTION", tabValue: PROFILE_TAB_NAMES.CONNECTION, permissions: [PERMISSIONS.VIEW_ADVANCE_INFO, PERMISSIONS.VIEW_ALL]},
  { tabKey: "RECENT_ACTIVITY", tabValue: PROFILE_TAB_NAMES.RECENT_ACTIVITY, permissions: [PERMISSIONS.VIEW_ADVANCE_INFO, PERMISSIONS.VIEW_ALL], path: "activity" },
  { tabKey: "OUTCOME_HISTORY", tabValue: PROFILE_TAB_NAMES.OUTCOME_CHANGES, permissions: [PERMISSIONS.VIEW_BASICS, PERMISSIONS.VIEW_ADVANCE_INFO, PERMISSIONS.VIEW_ALL], path: "outcome_changes" },
  { tabKey: "DEMOGRAPHIC_HISTORY", tabValue: PROFILE_TAB_NAMES.DEMOGRAPHIC_CHANGES, permissions: [PERMISSIONS.VIEW_ADVANCE_INFO, PERMISSIONS.VIEW_ALL], path: "demographic_changes" },
  { tabKey: "CONNECTION_HISTORY", tabValue: PROFILE_TAB_NAMES.CONNECTION_CHANGES, permissions: [PERMISSIONS.VIEW_ADVANCE_INFO, PERMISSIONS.VIEW_ALL], path: "connection_changes" }
];
export default PanelistEntry;
