import { useState } from 'react';
import { usePostReview } from './requests/usePostReview';
import { useProcessReviewRequest } from '../ReviewRequests/requests/useProcessReviewRequest';
import Review from '../../types/Review';


const ManualReviewForm: React.FC<{
  componentId: number,
  panelistId: string,
  reviewRequestId?: string | null,
  setReviewModal: React.Dispatch<React.SetStateAction<number>>,
  successCallback?: () => Promise<void>, 
  failCallback?: () => Promise<void> }> = ({ componentId, panelistId, reviewRequestId = null, setReviewModal, successCallback = () => Promise.resolve(), failCallback = () => Promise.resolve()}) => {

  const [manualReviewOutcome, setManualReviewOutcome] = useState('auto');
  const [postReviewLoadingState, setPostReviewLoadingState] = useState(false);
  const { postManualReview } = usePostReview();
  const { processReviewRequest } = useProcessReviewRequest();
  
  const setReviewOutcome = (event: any) => {
    setManualReviewOutcome((event.target as HTMLInputElement).value);
  };

  const submitReview = async (event: any) => {
    const genericAgreeReason = 'Reviewer wants Qubed to decide the panelist quality'

    const review = {
      outcome: manualReviewOutcome,
      reason: (document.getElementById(`review-reason-text-field:${componentId}`) as HTMLInputElement).value || genericAgreeReason
    }

    let endpointCallPromises = [ postManualReview(review, panelistId, setPostReviewLoadingState, setReviewModal) ]
    if (reviewRequestId) endpointCallPromises.push(processReviewRequest(review as Review, panelistId.split(':')[0], reviewRequestId));

    Promise.all(endpointCallPromises).catch(() => failCallback()).then( () => successCallback());

    event.preventDefault();
  };

  return (<form onSubmit={submitReview}>
    <div className="field">
      <label className="label">Choose one:</label>
      <div onChange={setReviewOutcome} className="control">
        <label className="radio">
          <input type="radio" name="question" value="good"/>
            <label> I believe this panelist is <b>good</b> </label>
        </label>
        <br />
        <label className="radio">
          <input type="radio" name="question" value="bad"/>
            <label> I believe this panelist is <b>bad</b> </label>
        </label>
        <br />
        <label className="radio">
          <input type="radio" name="question" value="auto" defaultChecked/>
            <label> I want Qubed to decide whether the panelist is <b>good</b> or <b>bad</b> </label>
        </label>
      </div>
    </div>
    <div className="field">
      <label className="label">{manualReviewOutcome === 'auto' ? 'Comments or remarks' : 'Reason'}</label>
      <div className="control">
        <input id={`review-reason-text-field:${componentId}`} name='review-reason-text-field' maxLength={128} className="input" type='text' required={manualReviewOutcome === 'auto' ? false : true}></input>
      </div>
    </div>
    <div className="field is-grouped">
      <div className="control">
        <button type='submit' className={ postReviewLoadingState ? "button is-link is-loading" : "button is-link" }>Submit</button>
      </div>
      <div className="control">
        <button onClick={ () => {setReviewModal(-1)}} type="button" className="button is-link is-light">Cancel</button>
      </div>
    </div>
  </form> )
};

export default ManualReviewForm;