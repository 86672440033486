import React, { useState , useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PanelistProfile from '../components/PanelistProfile/PanelistProfile';
import { useFetch } from '../hooks/useFetch';
import PanelistActivity from '../types/Panelist/PanelistActivity';
import DemographicChange from '../types/Panelist/DemographicChange';
import ConnectionChange from '../types/Panelist/ConnectionChange';
import OutcomeChange from '../types/Panelist/OutcomeChange';
import useAuthorization from '../components/Auth/useAuthorization';
import { USER_ROLES, USER_SINGLE_ROLES, PERMISSIONS } from '../types/User/UserRoles';
import PanelistEntry, { profile_Permission_Tabs } from '../types/Panelist/PanelistEntry';
import Home, { HomeProps } from './Home';
import { LinearProgress } from '@mui/material';
import ErrorPage from './ErrorPage';

type PanelistProfileData =
  | PanelistEntry
  | Array<PanelistActivity>
  | Array<DemographicChange>
  | Array<ConnectionChange>
  | Array<OutcomeChange>;


const PanelistProfileContainer: React.FC<{
  panels: Array<string>,
  selectedPanel: string,
  setSelectedPanel: React.Dispatch<React.SetStateAction<string>>
}> = ({ panels, selectedPanel, setSelectedPanel }) => {
  const [panelistEntryState, setPanelistEntryState] = useState({} as PanelistEntry);
  const [panelistActivityState, setPanelistActivityState] = useState([] as Array<PanelistActivity>);
  const [demographicChangesState, setDemographicChangesState] = useState([] as Array<DemographicChange>);
  const [connectionChangesState, setConnectionChangesState] = useState([] as Array<ConnectionChange>);
  const [outcomeChangesState, setOutcomeChangesState] = useState([] as Array<OutcomeChange>);
  const [searchPanelistLoadingState, setSearchPanelistLoadingState] = useState(false);
  const { callProtectedEndpoint } = useFetch();

  const { userAuthorities } = useAuthorization([...Object.values(USER_ROLES), ...Object.values(USER_SINGLE_ROLES)], [PERMISSIONS.VIEW_BASICS, PERMISSIONS.VIEW_ADVANCE_INFO, PERMISSIONS.VIEW_ALL]);

  const getPanelist = (async (panelistProfileId:string) => {
 
    setSearchPanelistLoadingState(true);

    const allowedRequests = userAuthorities.length === 0 ? profile_Permission_Tabs.filter(obj => obj.path !== undefined).map(panelistTab => panelistTab.path) : profile_Permission_Tabs
      .filter(tab => tab.permissions.some(permission => userAuthorities.includes(permission) && tab.path))
      .map(tab => tab.path);

    const promises: Promise<PanelistProfileData>[] = allowedRequests.map(endpoint =>
      callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/panelists/${panelistProfileId}/${endpoint}`)
    )

    Promise.all([callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/panelists/${panelistProfileId}`), ...promises]).then((results: PanelistProfileData[]) => {
      const combinedResult: PanelistProfileData = results.reduce((acc, result) => ({ ...acc, ...result }), {} as PanelistProfileData);
      const { demographicChanges, connectionChanges, outcomeChanges, panelistEntry, activity, }: any = combinedResult;

      // Reverse order of panelist reviews. (Latest reviews should come first)
      panelistEntry?.panelist?.reviews?.reverse();

      setPanelistEntryState({ ...panelistEntry });
      setPanelistActivityState(activity);
      setDemographicChangesState(demographicChanges);
      setConnectionChangesState(connectionChanges);
      setOutcomeChangesState(outcomeChanges);

      setSearchPanelistLoadingState(false);
    });
  });
  const { id , panel} = useParams();
  useEffect(() => {
    if(panel && id){
      setSelectedPanel(panel);
      getPanelist(id);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id, panel]);

  const homeProps : HomeProps = {
    panels,
    selectedPanel,
    setSelectedPanel,
    searchPanelistLoadingState,
    children: (!searchPanelistLoadingState ? <PanelistProfile panelistEntry={panelistEntryState} panelistActivity={panelistActivityState}
                demographicChanges={demographicChangesState} connectionChanges={connectionChangesState}
                outcomeChanges={outcomeChangesState} />:
                <LinearProgress />
                ),
  };
  return !searchPanelistLoadingState && panelistEntryState && !panelistEntryState.panelist_id? <ErrorPage errorCode={404}/>:<Home {...homeProps}/>
}

export default PanelistProfileContainer;
