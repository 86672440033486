import { useFetch } from "../../../hooks/useFetch";
import Panelist from "../../../types/Panelist/PanelistEntry";


export const useDiscardReviewRequest = () => {
  const { callProtectedEndpoint } = useFetch();
  const discardReviewRequest = async (panel: string, reviewRequestId: string): Promise<Panelist> => {
    const patchRequestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' }
    };

    return await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${panel}/review_requests/discard/${reviewRequestId}`, patchRequestOptions);
  }
  
  return { discardReviewRequest };
};
