


type TabItemProps = {
    value: string,
    className: string,
    onClick: () => void
}

export const TabItem = ({ onClick, value, className }: TabItemProps) => (
    <a onClick={onClick} className={className}>{value}</a>
);
