import { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import ReviewRequest from '../../../types/ReviewRequest';
import { formatDateTimestamp } from '../../../utils/FormatDateTimestamp';
import Pagination from '../../Pagination/Pagination';

const DiscardedReviewRequest: React.FC<{
  reviewRequest: ReviewRequest,
  openReviewRequestResponseModal: (reviewRequest: ReviewRequest) => Promise<void>}> = ({ reviewRequest, openReviewRequestResponseModal }) => {

  return (
    <>
      <tr>
        <td>{formatDateTimestamp(reviewRequest?.createdAt) || '-'}</td>
        <td>{reviewRequest.panelist_id.split(':')[1]}</td>
        <td>{reviewRequest.is_fraudulent.toString()}</td>
        <td>{reviewRequest.requested_by}</td>
        <td>{JSON.stringify(reviewRequest.warnings) || '-'}</td>
        <td>{reviewRequest.comment || '-'}</td>
        <td style={{border: 'none'}}><button onClick={ () => {openReviewRequestResponseModal(reviewRequest); }} className='button is-outlined is-small is-info'><FaIcons.FaSearch/></button></td>
      </tr>
    </>
  );
}

const ManualReviewModal: React.FC<{
  selectedReviewRequest: ReviewRequest,
  isModalActive: boolean,
  setModal: React.Dispatch<React.SetStateAction<boolean>>}> = ({ selectedReviewRequest, isModalActive, setModal }) => {

  return ( 
    <div className={ isModalActive ? 'modal is-active': 'modal'}>
      <div onClick={ () => {setModal(false)}} className='modal-background'></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Review Request Response</p>
          <button onClick={ () => {setModal(false)}} className='delete' aria-label='close'></button>
        </header>
        <section className='modal-card-body'>
          <table className='table is-bordered is-hoverable is-fullwidth'>
            <tbody>
              <tr>
                <th>respondent:</th>
                <td>{selectedReviewRequest.respondent?.toString() || '-'}</td>
              </tr>
              <tr>
                <th>discarded at:</th>
                <td>{selectedReviewRequest.response_received_at?.toString() || '-'}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <footer className='modal-card-foot'></footer>
      </div>
    </div>
  );
}

const DiscardedReviewRequestsPage: React.FC<{
  pageEntries: Array<ReviewRequest> | undefined}> = ({ pageEntries }) => {
  let reviewRequestElements: JSX.Element[] = [];

  const [modalOpened, setModalOpened] = useState(false);
  const [selectedReviewRequest, setSelectedReviewRequest] = useState({} as ReviewRequest);

  const openReviewRequestResponseModal = (async (reviewRequest: ReviewRequest) => {
    setModalOpened(true);
    setSelectedReviewRequest(reviewRequest);
  });

  pageEntries?.forEach((reviewRequest: any) => {
    reviewRequestElements?.push(
    <DiscardedReviewRequest 
      reviewRequest={reviewRequest}
      openReviewRequestResponseModal={openReviewRequestResponseModal}/>)
  });

  return (
    <div className='panel-block is-size-7'>
      <table className='table is-bordered is-hoverable is-fullwidth'>
        <tbody>
          <tr className='table-header-row'>
            <th>timestamp</th>
            <th>panelist id</th>
            <th>is fraudulent</th>
            <th>requested by</th>
            <th>warnings</th>
            <th>comment</th>
          </tr>
          {reviewRequestElements}
        </tbody>
      </table>
      <div className='panel-block'>
        <div className={ modalOpened ? 'modal is-active': 'modal'}>
          <ManualReviewModal
            selectedReviewRequest={selectedReviewRequest}
            isModalActive={modalOpened}
            setModal={setModalOpened}/>
        </div>
      </div>
    </div>
  )
}

const DiscardedRequestsTab: React.FC<{
  reviewRequests: Array<ReviewRequest> | undefined}> = ({ reviewRequests }) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  
  const chunkSize = 5;
  const activityChunk = reviewRequests?.slice(chunkSize*selectedPageIndex, chunkSize*selectedPageIndex + chunkSize);

  return (
    <>
      <DiscardedReviewRequestsPage 
        pageEntries={activityChunk}/>
      <div className='panel-block'>
        <Pagination 
          pagesAmount={Math.ceil(( reviewRequests?.length || 0 )/chunkSize)}
          selectedPageIndex={selectedPageIndex}
          setSelectedPageIndex={setSelectedPageIndex}/>
      </div>
    </>
  );
}

export { DiscardedRequestsTab };
