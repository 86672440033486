import React, { useState } from 'react';
import { DataGrid, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import { Audit, PanelistReview } from '../../../types/ManualReview';
import { Box, IconButton, Tooltip, Typography, styled } from '@mui/material';
import NoDataMessage from '../../../components/NoDataMessage/NoDataMessage';
import { AssessmentOutlined } from '@mui/icons-material';
import ModalForm from './ModalForm';
import { Link } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';

const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-columnHeaders {
    background-color: #4C63C8;
    color: #fff;

    &:hover {
      background-color: #4C63C8;
    }

    svg{
      color: #fff;
    }
  }
  .audited-row {
    background-color: #b7eb8f;
    &:hover {
      background-color: #73d13d;
    }
  }
`;

const StyledTableNote = styled(Typography)`
  margin-top: -35px;
  padding-bottom: 15px;
`;

type ManualReviewsTableProps = {
  panelistReviews: PanelistReview[],
  refreshReviewList: () => void
}

const ManualReviewsTable: React.FC<ManualReviewsTableProps> = ({ panelistReviews, refreshReviewList }) => {
  const [isAuditDialogOpen, setAuditDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const renderPanelistIdLink = (params: GridRenderCellParams) => {
    if(params.row.panelistId){
      const pathVars = params.row.panelistId.split(":");
      const url = `/panelists/${pathVars[0]}/${pathVars[1]}`;
    
      return (
        <Link to={url} target="_blank">
          {params.value}
        </Link>
      );
    }
  };

  const columns = [
    {
      field: 'panelistId',
      headerName: 'Panelist ID',
      width: 150,
      sortable: false,
      renderCell: renderPanelistIdLink
    },
    { field: 'outcome', headerName: 'Outcome', width: 130 },
    { field: 'reason', headerName: 'Reason', width: 200, sortable: false, },
    { field: 'author', headerName: 'Author', width: 202, sortable: false, },
    { field: 'timestamp', headerName: 'Timestamp', width: 200, type: 'dateTime',
    valueGetter: ({ value }: {value: string}) => value && new Date(value) },
    {
      field: 'action',
      headerName: '',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title="Audit Review" arrow>
          <IconButton
            color="primary"
            onClick={() => handleAuditButtonClick(params.row)}
          >
            <AssessmentOutlined />
          </IconButton>
        </Tooltip>
      ),
    },
  ];
  
 

  const rows = panelistReviews?.flatMap((reviewRecord) =>
  reviewRecord.reviews.map((item, index) => ({
      id: `${reviewRecord.panelist_id}_${index}`,
      panelistId: reviewRecord.panelist_id,
      outcome: item.outcome || '-',
      reason: item.reason || '-',
      author: item.author,
      timestamp: item.timestamp || '-',
      is_audited: item.is_audited
    }))
  );
  
  const handleAuditButtonClick = (record: GridValidRowModel) => {
      setSelectedRow(record);
      setAuditDialogOpen(true);
  };

  const getAuditOptions = () => {
    const commonPart = 'I want to revert this panelist to ';
    let value: string;
    const { outcome } = selectedRow as GridValidRowModel ;
    if (outcome === 'good') {
      value = `${commonPart}bad`;
    } else {
      value = `${commonPart}good`;
    }
    return [
      {
        name:'success',
        value: `I want to keep this review as it is`
      },
      {
        name:'failed',
        value
      }
    ]
  }
  const { callProtectedEndpoint } = useFetch();
  const submitAuditRequest = async (body: Audit, panel: string) => {
    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };
      return await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${panel}/audits`, postRequestOptions).then(()=> refreshReviewList());
  };

  const handleAuditSubmit = (auditStatus: string, comment?: string) => {
    const {panelistId, outcome, reason, author, timestamp } = selectedRow as GridValidRowModel;
    let audit = {
        panelist_id: panelistId,
        original_reviewer: author,
        previous_outcome: outcome,
        audit_status: auditStatus,
        reason: auditStatus === 'failed'? comment : reason,
    }
    if(auditStatus === 'success'){
      audit = {...audit , timestamp} as Audit;
    }
   return submitAuditRequest(audit, panelistId.split(":")[0]);
  }

  return (
    <Box style={{ width: '100%' }}>
      {
        rows?.length ?
        <StyledDataGrid rows={rows} columns={columns} pagination
       initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20]} 
        disableRowSelectionOnClick
        getRowClassName={(params: GridValidRowModel) =>` ${params.row.is_audited ? 'audited-row' : ''}`}/>
        :<NoDataMessage message={'There\'s no panel\'s reviews available for last 2 weeks.'} />
      }
       <StyledTableNote variant="body2" gutterBottom color="textSecondary" ml={2}>
          Note: The highlighted rows are already audited.
        </StyledTableNote>
      <ModalForm
        title='Audit Review'
        open={isAuditDialogOpen}
        onClose={() => setAuditDialogOpen(false)}
        options={getAuditOptions()}
        onSubmitClick={handleAuditSubmit}
        submitSuccessMessage={'Your audit has been sent successfully!'}
      />
    </Box>
  );
};

export default ManualReviewsTable;
