import { useState } from "react";
import useAuthorization from "../Auth/useAuthorization";
import PanelistEntry from "../../types/Panelist/PanelistEntry";
import PanelistActivity from "../../types/Panelist/PanelistActivity";
import ConnectionChange from "../../types/Panelist/ConnectionChange";
import DemographicChange from "../../types/Panelist/DemographicChange";
import OutcomeChange from "../../types/Panelist/OutcomeChange";
import Filter from "../../types/Panelist/Filter";
import { ConnectionTab } from "./Tabs/ConnectionTab/ConnectionTab";
import { ConnectionChangesTab } from "./Tabs/ConnectionChangesTab/ConnectionChangesTab";
import { DemographicsTab } from "./Tabs/DemographicsTab/DemographicsTab";
import { DemographicChangesTab } from "./Tabs/DemographicChangesTab/DemographicChangesTab";
import { LatestOutcomeTab } from "./Tabs/LatestOutcomeTab/LatestOutcomeTab";
import { OutcomeChangesTab } from "./Tabs/OutcomeChangesTab/OutcomeChangesTab";
import { RecentActivityTab } from "./Tabs/RecentActivityTab/RecentActivityTab";
import { PERMISSIONS, USER_ROLES, USER_SINGLE_ROLES } from "../../types/User/UserRoles";
import { PROFILE_TAB_NAMES, profile_Permission_Tabs } from "../../types/Panelist/PanelistEntry";
import { TabsBar } from "./Tabs/Components/TabsBar";

const PanelistProfile: React.FC<{
  panelistEntry: PanelistEntry;
  panelistActivity: Array<PanelistActivity>;
  demographicChanges: Array<DemographicChange>;
  connectionChanges: Array<ConnectionChange>;
  outcomeChanges: Array<OutcomeChange>;
}> = ({
  panelistEntry,
  panelistActivity,
  demographicChanges,
  connectionChanges,
  outcomeChanges
}) => {
    const [activeTab, setActiveTab] = useState(PROFILE_TAB_NAMES.LATEST_OUTCOME);
    const [activityFilters, setActivityFilters] = useState([] as Array<Filter>);

    const PanelistProfileTabSwitch = (props: any) => {
      switch (props.tabChoice) {
        case PROFILE_TAB_NAMES.LATEST_OUTCOME:
          return (
            <LatestOutcomeTab
              {...panelistEntry}
              qubedOutcome={panelistEntry.latest_qubed_outcome}
            />
          );
        case PROFILE_TAB_NAMES.DEMOGRAPHICS:
          return <DemographicsTab {...panelistEntry?.demographics} />;
        case PROFILE_TAB_NAMES.CONNECTION:
          return <ConnectionTab {...panelistEntry?.connection} />;
        case PROFILE_TAB_NAMES.RECENT_ACTIVITY:
          return (
            <RecentActivityTab
              recentActivity={panelistActivity}
              allReasons={panelistEntry?.all_reasons || []}
              searchFilters={activityFilters}
              setSearchFilters={setActivityFilters}
            />
          );
        case PROFILE_TAB_NAMES.DEMOGRAPHIC_CHANGES:
          return (
            <DemographicChangesTab demographicChanges={demographicChanges} />
          );
        case PROFILE_TAB_NAMES.CONNECTION_CHANGES:
          return <ConnectionChangesTab connectionChanges={connectionChanges} />;
        case PROFILE_TAB_NAMES.OUTCOME_CHANGES:
          return (
            <OutcomeChangesTab
              outcomeChanges={outcomeChanges}
            />
          );
        default:
          return <></>;
      }
    };

    const { isAuthorized, userAuthorities } = useAuthorization(
      [...Object.values(USER_ROLES), ...Object.values(USER_SINGLE_ROLES)],
      [
        PERMISSIONS.VIEW_BASICS,
        PERMISSIONS.VIEW_ADVANCE_INFO,
        PERMISSIONS.VIEW_ALL,
      ]
    );

    const authorizedProfileTabs =
      userAuthorities.length === 0
        ? profile_Permission_Tabs
        : profile_Permission_Tabs.filter((obj) =>
          userAuthorities.some((authority: any) =>
            obj.permissions.includes(authority)
          )
        );
    const { latest_qubed_outcome, panelist_id } = panelistEntry;
    return (
      <nav className="panel is-dark">
        {isAuthorized ? (
          <>
            <p className="panel-heading text-centered">Panelist Profile</p>
            <div className="panel-block">
              <table className="table is-bordered is-fullwidth">
                <tbody>
                  <tr className="text-centered">
                    <th>panelist id:</th>
                    <td>{panelist_id?.split(":")[1] || "-"}</td>
                  </tr>
                  <tr className="text-centered">
                    <th>panelist quality:</th>
                    <td>{latest_qubed_outcome?.qubed_api_response?.user_quality || 'Good'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TabsBar
              {...{
                profileTabs: [...authorizedProfileTabs],
                activeTab,
                setActiveTab,
              }}
            />
            <PanelistProfileTabSwitch tabChoice={activeTab} />
          </>
        ) : (
          <></>
        )}
      </nav>
    );
  };

export default PanelistProfile;
