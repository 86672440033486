import { useLocation, useNavigate } from "react-router-dom";

const PanelDropdownItem: React.FC<{
    panel: string,
    setSelectedPanel: React.Dispatch<React.SetStateAction<string>> }> = ({ panel, setSelectedPanel }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleOnClick = () => {
        setSelectedPanel(panel);
        if(pathname !== '/reviews'){
          navigate('/panelists');
        }
    } 
    return (
      <a onClick={handleOnClick} className='navbar-item'>
        {panel}
      </a>
    );
  };
  
  const PanelDropdown: React.FC<{ panels: Array<string>,
    setSelectedPanel: React.Dispatch<React.SetStateAction<string>> }> = ({ panels, setSelectedPanel }) => {
    let panelsDropdownItems: JSX.Element[] = [];
  
    panels.forEach((panel: string, index: number ) => {
      panelsDropdownItems.push(<PanelDropdownItem key={index.toString()} panel={panel} setSelectedPanel={setSelectedPanel}/>)
    });
  
    return (
      <div className="navbar-dropdown">
        {panelsDropdownItems}
      </div>
    );
  };

  export default PanelDropdown;