import { useState } from "react";
import OutcomeReview from "../../../../../types/Panelist/OutcomeReview";
import ReviewButtons from "./ReviewButtons";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import ManualReviewList from "./ManualReviewList";
import ModalForm from "../../../../../pages/AuditsPage/components/ModalForm";
import { usePostReview } from "../requests/usePostReview";
import { useProcessReviewRequest } from "../../../../ReviewRequests/requests/useProcessReviewRequest";
import Review from "../../../../../types/Review";


type ReviewModalProps = {
    hasDuplications: boolean;
    panelistId: string;
    reviews: Array<OutcomeReview>;
    reviewRequestId?: string | null,
}

const ReviewModal = ({ ...props }: ReviewModalProps) => {
    const { hasDuplications, panelistId, reviews, reviewRequestId } = props;
    const [isPreviousReviewsModalActive, setPreviousReviewsModal] = useState(false);
    const [isReviewModalActive, setReviewModal] = useState(false);

    const [isErrorMessageActive, setErrorMessageActive] = useState(false);


    const { postManualReview } = usePostReview();
    const { processReviewRequest } = useProcessReviewRequest();

    const ReviewOptions = [
        { name: 'good', value: 'I believe this panelist is good' },
        { name: 'bad', value: 'I believe this panelist is bad' },
        { name: 'auto', value: 'I want Qubed to decide whether the panelist is good or bad' },
    ];

    const submitReview = async (outcome: string, comment?: string) => {
        const genericAgreeReason = 'Reviewer wants Qubed to decide the panelist quality'

        const review = {
            outcome,
            reason: comment || genericAgreeReason
        }

        let endpointCallPromises = [postManualReview(review, panelistId)]
        if (reviewRequestId) endpointCallPromises.push(processReviewRequest(review as Review, panelistId.split(':')[0], reviewRequestId));

        return Promise.all(endpointCallPromises);
    };

    return (<>
        {hasDuplications && <ReviewButtons panelistId={panelistId} hasPreviousReviews={reviews.length > 0} setPreviousReviewsModal={setPreviousReviewsModal} setReviewModal={setReviewModal} />}
        <ModalForm
            title='Manual Review'
            open={isReviewModalActive}
            onClose={() => setReviewModal(false)}
            options={ReviewOptions}
            onSubmitClick={submitReview}
            submitSuccessMessage="Your review has been sent successfully and will be processed accordingly."
        />

        <div className={isPreviousReviewsModalActive ? 'modal is-active' : 'modal'}>
            <div onClick={() => { setPreviousReviewsModal(false) }} className='modal-background'></div>
            <div className='modal-card'>
                <header className='modal-card-head'>
                    <p className='modal-card-title'>Previous Reviews</p>
                    <button onClick={() => { setPreviousReviewsModal(false) }} className='delete' aria-label='close'></button>
                </header>
                <section className='modal-card-body'>
                    <div className='panel'>
                        <ManualReviewList outcomeReviews={reviews} />
                    </div>
                </section>
                <footer className='modal-card-foot'></footer>
            </div>
        </div>
        <div className='panel-block'>
            {isErrorMessageActive ?
                <ErrorMessage
                    setErrorMessageActive={setErrorMessageActive}
                    header={'Error'}
                    message={'Something went wrong. Please try again later.'} /> : <></>}
        </div>
    </>);


}

export default ReviewModal;