import Demographics from '../../../../types/Panelist/Demographics'
import NoDataMessage from '../../../NoDataMessage/NoDataMessage'
import { DemographicsTable } from './DemographicsTable'

const DemographicsTab = (demographics: Demographics) => {
  return (
    <div className='panel-block'>
      {Object.entries(demographics).length > 0 ?
        <DemographicsTable demographics={demographics} />
        : <NoDataMessage message={'There\'s no panelist demographic information available at this time.'} />
      }
    </div>
  )
}

export { DemographicsTab }
