import Session from '../../../../types/Panelist/Session';

const SessionTable: React.FC<{
  session: Session | null | undefined }> = ({ session }) => {
  return (
    <div className='panel-block'>
      <table className='table is-bordered is-hoverable is-striped is-fullwidth'>
        <tbody>
          <tr>
            <th colSpan={2}>Event</th>
          </tr>
          <tr>
            <th>name:</th>
            <td>{session?.event?.name || '-'}</td>
          </tr>
          <tr>
            <th>received at:</th>
            <td>{session?.event?.received_at || '-'}</td>
          </tr>
          <tr>
            <th>reconciled:</th>
            <td>{session?.event?.reconciled || '-'}</td>
          </tr>
          <tr>
            <th>survey id:</th>
            <td>{session?.event?.survey_id || '-'}</td>
          </tr>
          <tr>
            <th>timestamp:</th>
            <td>{session?.event?.timestamp || '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className='table is-bordered is-hoverable is-striped is-fullwidth'>
        <tbody>
          <tr>
            <th colSpan={2}>Survey</th>
          </tr>
          <tr>
            <th>category:</th>
            <td>{session?.survey?.category || '-'}</td>
          </tr>
          <tr>
            <th>client:</th>
            <td>{session?.survey?.client || '-'}</td>
          </tr>
          <tr>
            <th>cpi:</th>
            <td>{session?.survey?.cpi || '-'}</td>
          </tr>
          <tr>
            <th>cpi currency code:</th>
            <td>{session?.survey?.cpi_currency_code || '-'}</td>
          </tr>
          <tr>
            <th>cr:</th>
            <td>{session?.survey?.cr || '-'}</td>
          </tr>
          <tr>
            <th>finished at:</th>
            <td>{session?.survey?.finished_at || '-'}</td>
          </tr>
          <tr>
            <th>ir:</th>
            <td>{session?.survey?.ir || '-'}</td>
          </tr>
          <tr>
            <th>loi:</th>
            <td>{session?.survey?.loi || '-'}</td>
          </tr>
          <tr>
            <th>started at:</th>
            <td>{session?.survey?.started_at || '-'}</td>
          </tr>
          <tr>
            <th>status detail:</th>
            <td>{session?.survey?.status_detail || '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export { SessionTable };
