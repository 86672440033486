import Connection from '../../../../types/Panelist/Connection'

const ConnectionTab = (connection: Connection) => {
  return (
    <div className='panel-block'>
      <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <tbody>
          <tr>
            <th>anonymous proxy:</th>
            <td>{connection?.anonymous_proxy?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>asn:</th>
            <td>{connection?.asn?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>asn organisation:</th>
            <td>{connection?.asn_organisation?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>datacenter:</th>
            <td>{connection?.datacenter?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>high risk:</th>
            <td>{connection?.high_risk?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>ip:</th>
            <td>{connection?.ip?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>ip risk:</th>
            <td>{connection?.ip_risk?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>is mobile network:</th>
            <td>{connection?.is_mobile_network?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>isp:</th>
            <td>{connection?.isp?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>mobile country code:</th>
            <td>{connection?.mobile_country_code?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>mobile network code:</th>
            <td>{connection?.mobile_network_code?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>organisation:</th>
            <td>{connection?.organisation?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>risk score:</th>
            <td>{connection?.risk_score?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>risk source:</th>
            <td>{connection?.risk_source?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>satellite provider:</th>
            <td>{connection?.satellite_provider?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>tor exit node:</th>
            <td>{connection?.tor_exit_node?.toString() || '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <tbody>
          <tr>
            <th colSpan={2}>Country</th>
          </tr>
          <tr>
            <th>alpha2:</th>
            <td>{connection?.country?.alpha2?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>alpha3:</th>
            <td>{connection?.country?.alpha3?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>name:</th>
            <td>{connection?.country?.name?.toString() || '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <tbody>
          <tr>
            <th colSpan={2}>Actual Country</th>
          </tr>
          <tr>
            <th>alpha2:</th>
            <td>{connection?.actual_country?.alpha2?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>alpha3:</th>
            <td>{connection?.actual_country?.alpha3?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>name:</th>
            <td>{connection?.actual_country?.name?.toString() || '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <tbody>
          <tr>
            <th colSpan={2}>Registered Country</th>
          </tr>
          <tr>
            <th>alpha2:</th>
            <td>{connection?.registered_country?.alpha2?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>alpha3:</th>
            <td>{connection?.registered_country?.alpha3?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>name:</th>
            <td>{connection?.registered_country?.name?.toString() || '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <tbody>
          <tr>
            <th colSpan={2}>Represented Country</th>
          </tr>
          <tr>
            <th>alpha2:</th>
            <td>{connection?.represented_country?.alpha2?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>alpha3:</th>
            <td>{connection?.represented_country?.alpha3?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>name:</th>
            <td>{connection?.represented_country?.name.toString() || '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className='table is-bordered is-striped is-hoverable is-fullwidth'>
        <tbody>
          <tr>
            <th colSpan={2}>Continent</th>
          </tr>
          <tr>
            <th>alpha2:</th>
            <td>{connection?.continent?.alpha2?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>alpha3:</th>
            <td>{connection?.continent?.alpha3?.toString() || '-'}</td>
          </tr>
          <tr>
            <th>name:</th>
            <td>{connection?.continent?.name?.toString() || '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export { ConnectionTab };
