import { useFetch } from "../../../hooks/useFetch";
import OutcomeReview from "../../../types/Panelist/OutcomeReview";
import Panelist from "../../../types/Panelist/PanelistEntry";


export const usePostReview = () => {
  const { callProtectedEndpoint } = useFetch();
  const postManualReview = async (review: OutcomeReview, panelistId: string,
    setPostReviewLoadingState: React.Dispatch<React.SetStateAction<boolean>>,
    setReviewModal: React.Dispatch<React.SetStateAction<number>>): Promise<Panelist> => {
    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(review),
    };

    setPostReviewLoadingState(true);

    let [ panel, id ] = panelistId.split(':');

    const endpointResponse = await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${panel}/panelists/${id}/outcome_review`, postRequestOptions);

    setPostReviewLoadingState(false);
    setReviewModal(-1);
  
    return endpointResponse;
  }
  
  return { postManualReview };
};
