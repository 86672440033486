import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar/Navbar';
import ErrorMessage from '../components/ErrorMessage/ErrorMessage';
import ReviewRequestsComponent from '../components/ReviewRequests/ReviewRequests';
import SucccessMessage from '../components/SuccessMessage/SuccessMessage';
import { useFetch } from '../hooks/useFetch';
import ReviewRequest from '../types/ReviewRequest';

const ReviewRequests: React.FC<{
  panels: Array<string>,
  selectedPanel: string,
  setSelectedPanel: React.Dispatch<React.SetStateAction<string>> }> = ({ panels, selectedPanel, setSelectedPanel }) => {
  const [requestedReviewRequestsState, setRequestedReviewRequestsState] = useState([] as ReviewRequest[]);
  const [processedReviewRequestsState, setProcessedReviewRequestsState] = useState([] as ReviewRequest[]);
  const [discardedReviewRequestsState, setDiscardedReviewRequestsState] = useState([] as ReviewRequest[]);
  const [isReviewSentMessageActive, setReviewSentMessageActive] = useState(false);
  const [isRequestDiscardedMessageActive, setRequestDiscardedMessageActive] = useState(false);
  const [isErrorMessageActive, setErrorMessageActive] = useState(false);

  const { callProtectedEndpoint } = useFetch();

  const refreshReviewRequests = (async (): Promise<void> => {
    setRequestedReviewRequestsState(await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/review_requests/requested`));
    setProcessedReviewRequestsState(await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/review_requests/processed`));
    setDiscardedReviewRequestsState(await callProtectedEndpoint(`${process.env.REACT_APP_QUBED_ADMIN_BASE_URL}/${selectedPanel}/review_requests/discarded`));
  });

  const onReviewRequestProcess = (async (): Promise<void> => {
    refreshReviewRequests();
    setErrorMessageActive(false);
    setRequestDiscardedMessageActive(false);
    setReviewSentMessageActive(true);
  });

  const onReviewRequestDiscard = (async (): Promise<void> => {
    refreshReviewRequests();
    setErrorMessageActive(false);
    setReviewSentMessageActive(false);
    setRequestDiscardedMessageActive(true);
  });

  const onFailedReviewRequestAction= (async (): Promise<void> => {
    setReviewSentMessageActive(false);
    setRequestDiscardedMessageActive(false);
    setErrorMessageActive(true);
  })

  useEffect(() => {
    refreshReviewRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='fixed-navbar-padding'>
      {}
      <Navbar panels={panels} selectedPanel={selectedPanel} setSelectedPanel={setSelectedPanel}/>
      <div className='container is-max-desktop panelists'>
        <section className='section'>
          {isReviewSentMessageActive && 
            <SucccessMessage 
              setSuccessMessageActive={setReviewSentMessageActive} 
              header={'Review Sent'} 
              message={'Your review has been sent successfully and will be processed accordingly.'}/>}
          {isRequestDiscardedMessageActive && 
            <SucccessMessage 
              setSuccessMessageActive={setRequestDiscardedMessageActive}
              header={'Request Discarded'} 
              message={'The review request has been discarded successfully.'}/>}
          {isErrorMessageActive && 
            <ErrorMessage 
              setErrorMessageActive={setErrorMessageActive}
              header={'Error'}
              message={'Something went wrong. Please try again later.'}/>}
          <ReviewRequestsComponent 
            requestedReviewRequests={requestedReviewRequestsState}
            processedReviewRequests={processedReviewRequestsState}
            discardedReviewRequests={discardedReviewRequestsState}
            onReviewRequestProcess={onReviewRequestProcess}
            onReviewRequestDiscard={onReviewRequestDiscard}
            onFailedReviewRequestAction={onFailedReviewRequestAction}/>
        </section>
      </div>
    </div>
  )
}

export default ReviewRequests;