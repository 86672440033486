import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import PanelistLookupForm from '../components/PanelistLookupForm/PanelistLookupForm';

export type HomeProps = {
    panels: string[],
    selectedPanel: string,
    setSelectedPanel: React.Dispatch<React.SetStateAction<string>>,
    searchPanelistLoadingState?: boolean,
    getPanelist?: () => any,
    children?: React.ReactNode;
};

const Home = ({ ...homeProps }: HomeProps) => {
    const {
        panels,
        selectedPanel,
        setSelectedPanel,
        searchPanelistLoadingState,
        children,
    } = homeProps;
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const navigateToPanelistProfile = (id: string) => {
        navigate(`/panelists/${selectedPanel}/${id}`);
    };
    return (
        <div className='fixed-navbar-padding'>
            <Navbar panels={panels} selectedPanel={selectedPanel} setSelectedPanel={setSelectedPanel} />
            <div className='container is-max-desktop panelists'>
                {pathname !== '/reviews' && (
                    <section className='section'>
                        <PanelistLookupForm loadingState={searchPanelistLoadingState} getPanelistProfile={navigateToPanelistProfile} />
                    </section>
                )}

                <section className='section'>
                    {children}
                </section>
            </div>
        </div>
    );
};

export default Home;
